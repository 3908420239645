import { useState } from 'react';
import { DropdownProps } from './index.types';
import Icon from 'src/modules/General/components/Icon';
import Chip from '@mui/material/Chip';
import useEmailsLeftColumn from 'src/pages/Emails/LeftColumn/index.services';

const DropDown = ({ emails, onEmailClick, onRemoveEmail,onActiveOrDeactiveEmail,onChangePassword }: DropdownProps) => {
  const { pageSize } = useEmailsLeftColumn();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const clickEmail = (isConnected:boolean, email: string, count: number, number: number) => {
    if (count) setSelectedOption(email);
    onEmailClick?.(email, count, number, isConnected);
    setIsOpen(false);
  };
  const handleRemoveEmail = (email: string) => {
    setSelectedOption('');
    onRemoveEmail?.(email);
    setIsOpen(false);
  };
  const handleActiveOrDeactiveEmail = (active: boolean, email: string) => {
    // event.stopPropagation();
    onActiveOrDeactiveEmail?.(active, email);
    setIsOpen(false);
  };
  const handleChangePassword = (email: string, host: string) => {
  
    onChangePassword?.(email, host);
    setIsOpen(false);
  };
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className=" rounded-md border border-gray-300 shadow-sm px-4 py-2 w-32 truncate bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 max-sm:text-xs"
          onClick={() => setIsOpen(!isOpen)}>
          {selectedOption || 'Select an Email'}
        </button>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          <div className="py-1">
            {emails.map((email, index) => (
              <div className="w-full flex items-center gap-2 px-4 py-2 border-b last:border-none" key={index}>
                <div className={`w-3 h-3 ${email.active && email?.is_connected ? "bg-emerald-600" : "bg-red-600"}  rounded-full`} />
                
                <p
                  key={index}
                  className="text-sm text-gray-700 cursor-pointer max-sm:text-xs"
                  onClick={() => clickEmail?.(email.is_connected ?? true,email.email, email.count, pageSize)}
                  role="menuitem">
                  {email.email}
                </p>
                <Chip label={email.count} />
                <Icon name="trash" className="ml-2" onClick={() => handleRemoveEmail(email.email)} />
                <Icon name={email.active ? 'deactive' : 'active'} className="ml-2" onClick={() => handleActiveOrDeactiveEmail(email.active, email.email)} />
                {!email.is_connected && <Icon name='password' className="ml-2 w-8" onClick={() => handleChangePassword( email.email, email.host ?? "")} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
