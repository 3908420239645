import Modal from 'src/modules/General/components/Modal';
import { NotificationEmailDialogProps } from './index.types';
import { formatDate } from 'src/core/utilities/general';
import { useTranslation } from 'react-i18next';

const NotificationEmailDialog = ({ open, onClose, email }: NotificationEmailDialogProps) => {
  //   const { openPreviewModal, closeDialog, resolveNotification, onPreviewDialog, pdfData,onRetry,loadingDownload } = notificationDialogService();
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className=" bg-white flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-sm font-semibold">Email</span>
        </div>
        <div className="w-full flex flex-col items-start justify-normal gap-2 border-dashed border-b-2 pb-2">
          <div className="flex items-start justify-between flex-row gap-4 max-md:block">
            <p className="text-neutral-950 text-lg font-medium">
              {t('Sender')} : <span className="text-neutral-800 text-base font-medium">{email?.Sender}</span>
            </p>
            <p className="text-neutral-950 text-lg font-medium">
              {t('Date')} :{' '}
              <span className="text-neutral-800 text-base font-medium">{formatDate(email?.EmailDate ?? '')}</span>
            </p>
          </div>
          <div className="flex items-start justify-between flex-row">
            <p className="text-neutral-950 text-lg font-medium">
              {t('Subject')} :{' '}
              <span className="text-neutral-800 text-base font-medium max-w-96 overflow-clip">
                {email?.EmailSubject}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full max-h-64 bg-white rounded-lg py-4  overflow-y-auto">
          {email?.EmailContentRaw && email.EmailContentRaw != null ? (
            <div dangerouslySetInnerHTML={{ __html: email.EmailContentRaw }} className="w-full"></div>
          ) : email?.EmailContent && email.EmailContent != null ? (
            <div>
              <div className="w-full">
                {email.EmailContent.split('\n').map((line, index) => (
                  <p key={index} className="text-neutral-700 text-base font-normal leading-tight">
                    {line}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="w-full flex flex-row gap-2 items-center justify-end">
          <button
            onClick={onClose}
            className="w-28 h-11 px-4 py-3 bg-white rounded-xl shadow border border-red-600 justify-center items-center gap-2 inline-flex text-red-600">
            {t('Close')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationEmailDialog;
