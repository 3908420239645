import { useState } from 'react';
import { EmailDataType } from '../../containers/ContentEmailDialog/index.types';
import { downloadRecentFile } from '../../api';

const MessageService = () => {
  const [emailSelected, setEmailSelected] = useState<EmailDataType>();
  const [openDialogEmail, setOpenDialogEmail] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [fileNameDownload, setFileNameDownload] = useState<string>('');
  const [fileIdDownload, setFileIdDownload] = useState<number>(0);
  const closeDialogEmail = () => {
    setOpenDialogEmail(false);
  };
  const showContentEmail = (email: EmailDataType) => {
    setEmailSelected(email);
    setOpenDialogEmail(true);
  };
  const onPreviewDialog = (fileName: string, documentId: number) => {
    setFileIdDownload(documentId);
    setFileNameDownload(fileName);
    setOpenConfirmationModal({ type: 'file', open: true });
    downloadDataFile(fileName, documentId);
  };
  const downloadDataFile = (fileName: string, documentId: number) => {
    setLoadingDownload(true);
    downloadRecentFile(fileName, documentId).then((result) => {
      if (typeof result === 'object') {
        setLoadingDownload(false);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const uint8Array = new Uint8Array(reader.result);
            if (uint8Array.length === 0) {
              return;
            }
            setPdfData(new Uint8Array(uint8Array));
          }
        };
        reader.readAsArrayBuffer(result);
      }
      setLoadingDownload(false);
    });
  };
  const onRetry = () => {
    if (fileNameDownload && fileIdDownload) {
      downloadDataFile(fileNameDownload, fileIdDownload);
    }
  };
  const onCloseModal = () => {
    setPdfData(new Uint8Array());
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  return {
    openDialogEmail,
    closeDialogEmail,
    emailSelected,
    showContentEmail,
    loadingDownload,
    pdfData,
    onPreviewDialog,
    isModalOpen: openConfirmationModal.open,
    onRetry,
    onCloseModal,
  };
};

export default MessageService;
