import { useEffect, useState } from 'react';
import { addNote, BodyNote, deleteNote, editNote, fetchNotes, NoteData, NoteParams } from 'src/modules/Note/api';

export interface Note {
  NoteID: number;
  Note: string;
  CreatedDateTime: string;
  Label?: string;
}

export interface NoteDataTable {
  id?: number;
  label?: Item;
  notes: Note[];
}
export interface Label {
  id: number;
  value: string;
}
export interface Item {
  label: string;
  value: string;
}


const NoteServices = () => {
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState<boolean>(false);
  const [openShowMoreModal, setOpenShowMoreModal] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [noteContent, setNoteContent] = useState<string>('');
  const [noteDate, setNoteDate] = useState<string>('');
  const [noteId, setNoteId] = useState<number | null>(null);
  const [noteList, setNoteList] = useState<Note[]>([]);
  const [listening, setListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [itemSelected, setItemSelected] = useState<Item | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [supported, setSupported] = useState<boolean>(true);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [displaySnackbar, setDispalySnackbar] = useState<{ display: boolean; color: string; message: string }>({
    display: false,
    color: '',
    message: '',
  });
  const [messageResponse, setMessageResponse] = useState<string>('');
  const [selectedNotes, setSelectedNotes] = useState<number[]>([]);

  const handleSelectNote = (noteID: number) => {
    setSelectedNotes((prevSelected) =>
      prevSelected.includes(noteID)
        ? prevSelected.filter((id) => id !== noteID)
        : [...prevSelected, noteID]
    );
  };
  const handleSelectAll = () => {
    if (noteList?.length === selectedNotes.length) {
      setSelectedNotes([]);
    } else {
      const allNoteIds = noteList?.map((note) => note.NoteID) || [];
      setSelectedNotes(allNoteIds);
    }
  };
  
  const deleteNoteMsg = () => {
    setNote('');
    setNoteId(null);
  };

  const onListening = () => {
    if (!listening) {
      setNote('');
      setListening(true);
    } else {
      setListening(false);
    }
  };

  const onEdit = (selected: Note, label: string) => {
    setNoteId(selected.NoteID);
    setNote(selected.Note);
    setItemSelected({ label: label, value: label });
    setListening(false);
  };
  const onDelete = (noteID: number) => {
    setOpenDeleteNoteModal(true);
    setSelectedNotes([noteID])
    setListening(false);
  };
  const onDeleteMultiple = () => {
    setOpenDeleteNoteModal(true);
    setListening(false);
  };
  const handleListen = () => {
    setListening((prevListening) => !prevListening);
  };
  const onChangeLabel = (event: React.SyntheticEvent<Element, Event>, value: Item | null) => {
    setItemSelected(value);
  };

  const getNotes = async (params?: NoteParams) => {
    setLoadingFetch(true);
    const paramsNote: NoteParams = {
      number_of_records: 200,
    };
    const data = await fetchNotes();
    if (typeof data !== 'string') {
      setNoteList(data.notes.reverse());
    } else {
      const dataEmpty: NoteDataTable = {
        notes: [],
      };
      setNoteList(dataEmpty.notes);
    }
    setLoadingFetch(false);
  };

  const addNewNote = async () => {
    setListening(false);
    setLoading(true);
    const body: BodyNote = {
      text: note,
    };
    const data = await addNote(body);

    setNote('');
    setLoading(false);
    setDispalySnackbar({ message: 'Note Created', color: 'success', display: true });
    getNotes();
  };
  const updateNote = async () => {
    setLoading(true);
    setListening(false);
    if (noteId) {
      const data = await editNote(noteId, note);
      setLoading(false);
      setNoteId(null);
      setNote('');

      if (typeof data !== 'string') {
        setDispalySnackbar({
          message: 'Note Updated',
          color: 'success',
          display: true,
        });
        getNotes();
      } else {
        setDispalySnackbar({
          message: 'Error Update Note',
          color: 'error',
          display: true,
        });
      }
    }
  };
  const removeNote = async () => {
    setLoading(true);
    setListening(false);
    if (selectedNotes) {
      const data = await deleteNote(selectedNotes);
      setLoading(false);
      closeDeleteNoteDialog();

      if (typeof data !== 'string') {
        setDispalySnackbar({ display: true, message: `${selectedNotes.length > 1 ? 'Notes Deleted' : 'Note Deleted'} `, color: 'success' });
        getNotes();
        setSelectedNotes([])
      } else {
        setDispalySnackbar({ display: true, message: data, color: 'error' });
      }
    }
  };
  const handleCloseSnackbar = () => setDispalySnackbar({ display: false, message: '', color: '' });
  const closeDeleteNoteDialog = () => {
    setOpenDeleteNoteModal(false);
    setNoteId(null);
  };
  const openModalShowMore = (note: NoteData) => {
    setNoteContent(note.Note);
    setNoteDate(note.CreatedDateTime);
    setOpenShowMoreModal(true);
  };
  const closeModalShowMore = () => {
    setNoteContent('');
    setNoteDate('');
    setOpenShowMoreModal(false);
  };

  useEffect(() => {
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
      setSupported(false);
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      let finalTranscript = '';

      for (let i = 0; i < event.results.length; i++) {
        const result = event.results[i];

        if (result.isFinal) {
          finalTranscript += result[0].transcript;
        }
      }

      setNote(finalTranscript);
    };

    recognition.onerror = (event: any) => {
      console.error(event.error);
    };

    if (listening) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [listening]);
  useEffect(() => {
    getNotes();
  }, []);

  return {
    note,
    setNote,
    noteId,
    noteList,
    onEdit,
    onListening,
    listening,
    transcript,
    interimTranscript,
    deleteNoteMsg,
    onChangeLabel,
    itemSelected,
    addNewNote,
    loading,
    loadingFetch,
    displaySnackbar,
    messageResponse,
    handleCloseSnackbar,
    updateNote,
    openDeleteNoteModal,
    setOpenDeleteNoteModal,
    onDelete,
    closeDeleteNoteDialog,
    removeNote,
    noteContent,
    noteDate,
    openShowMoreModal,
    closeModalShowMore,
    openModalShowMore,
    supported,
    selectedNotes,
    handleSelectNote,
    handleSelectAll,
    onDeleteMultiple
  };
};

export default NoteServices;
