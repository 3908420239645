import React, { forwardRef, Ref, useEffect, useState } from 'react';
import useAuth from 'src/core/hooks/useAuth';
import Icon from 'src/modules/General/components/Icon';

interface DropdownMenuProps {
  onLogout: () => void;
}

const DropdownSideBar = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ onLogout }, ref) => {

  const [userInfo, setUserInfo] = useState<any>();
  useEffect(() => {
    if (localStorage?.getItem('userData')) {
      setUserInfo(JSON.parse(localStorage?.getItem('userData') ?? ''));
    }
  }, []);
    return (
      <div
        ref={ref}
        className="absolute top-82 right-340 mt-2 bg-white border border-gray-300 shadow-lg rounded-md w-80 p-4"
      >
        <div className="flex flex-col items-start mb-4">
          <p className="text-lg font-semibold text-gray-700"> {userInfo?.firstName + ' ' + userInfo?.lastName || 'User'}</p>
          <p className="text-sm text-gray-500 w-full ">{userInfo?.email}</p>
        </div>
        <button
          onClick={onLogout}
          className="w-full flex flex-row items-center gap-2 pl-0 p-2 text-red-600 hover:bg-gray-100 text-left rounded-md"
        >
            <Icon
                name="signout"
                className="w-8 h-8 flex items-center justify-center rounded-lg bg-light-blue-3 max-sm:w-6 max-sm:h-6"
              />
          Exit
        </button>
      
       
       
      </div>
    );
  }
);

DropdownSideBar.displayName = 'DropdownSideBar';

export default DropdownSideBar;
