import { useEffect, useState } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useQuereisContext } from 'src/core/contexts/queries.context';
import {
  downloadFile,
  downloadRecentFile,
  getRecentFiles,
  getRecentSearches,
  RecentDataType,
} from 'src/modules/Queries/api';
import { Item } from '../LeftColumn/InteractiveSearch/index.services';
import { fetchTags, Tag } from 'src/modules/General/api';
import { useTranslation } from 'react-i18next';

const useQueriesRightColumn = () => {
  const { hash } = useLocation();
  const { dispatch, state } = useQuereisContext();
  const { documents, searches, recent_files } = state || {};
  const [chatsList, setChatsList] = useState<any[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [loadingReset, setLoadingFilterReset] = useState<boolean>(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [isFiltered, setIsFiltred] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [category, setCategory] = useState<'chats' | 'documents'>('documents');
  const chatsCategory = category === 'chats';
  const isInteractiveSearchTab = hash.includes('interactive_search') || hash.includes('recherche_interactive');
  const isDocumentRetrievalTab =
    hash.includes('document_retrieval') || hash.includes('r%C3%A9cup%C3%A9ration_de_documents');

  const isAddDocumentTab = hash.includes('add_document') || hash.includes('ajouter_un_document');
  const documentsList = documents?.length
    ? documents.map((document) => ({ file_id: document.file_id, content: document.name, file_link: document.link }))
    : [];
    const [fileNameDownload, setFileNameDownload] = useState<string>('');
    const [fileIdDownload, setFileIdDownload] = useState<number>(0);
  useEffect(() => {
    getRecentFilesList();
    getRecentSearch();
    getTags();
  }, []);

  const toggleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  const getRecentFilesList = async () => {
    setLoadingFiles(true);
    const data = await getRecentFiles({ page_size: 20, page: 1 });
    const files = data?.result?.message;
    const recentFilesListConvert = files?.length
      ? files.map((file: any) => ({
          content: file?.DocumentName,
          file_id: file?.DocumentID,
          file_name: file?.DocumentName,
          file_link: file?.DocumentName,
          timestamp: file?.CreatedDateTime,
          blob_path: file?.blob_path,
        }))
      : [];

    dispatch({ type: 'recent_files', value: recentFilesListConvert });
    setLoadingFiles(false);
  };
  const getTags = async () => {
    const data = await fetchTags();
    if (typeof data !== 'string') {
      const tags: Item[] = (await data.tags_list?.length)
        ? data.tags_list.map((tag: Tag) => ({
            label: tag.tag_name,
            value: tag.tag_name,
          }))
        : [];

      setItems(tags);
    }
  };
  const getRecentSearch = async () => {
    const data: any = await getRecentSearches({ number_of_records: 40, page_size: 40 });
    console.log(data.result)
    if (typeof data.result != 'string') {
      const recentSearchesList = data?.result?.message?.length
      ? data?.result?.message?.map((search: any) => ({
          UserID: search.RSID,
          title: search.Question,
          content: search.Answer,
          time: search.Timestamp,
        }))
      : [];
    setChatsList(recentSearchesList);
   
    }
    else{
      setChatsList([])
    }
    
  };
  const onCloseModal = () => {
    setPdfData(new Uint8Array());
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  const { t } = useTranslation();
  const generateHeader = () => {
    if (isDocumentRetrievalTab) {
      return t('Recent Files');
    } else if (isInteractiveSearchTab) {
      if (chatsCategory) {
        return t('Chats History');
      }
      return t('Related Files');
    } else if (isAddDocumentTab) {
      return t('Recent Files');
    } else {
      return t('Recent Files');
    }
  };
  const onPreviewDialog = (fileName: string, documentId: number) => {
    setFileIdDownload(documentId)
    setFileNameDownload(fileName)
    setOpenConfirmationModal({ type: 'file', open: true });
    downloadDataFile(fileName, documentId);
  };
  const downloadDataFile = (fileName: string, documentId: number) => {
    setLoadingDownload(true)
      downloadRecentFile(fileName, documentId).then((result) => {
        if (typeof result === 'object') {
          setLoadingDownload(false)
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
              const uint8Array = new Uint8Array(reader.result);
              if (uint8Array.length === 0) {
                return;
              }
              setPdfData(new Uint8Array(uint8Array));
            }
          };
          reader.readAsArrayBuffer(result);
        }
        setLoadingDownload(false)
      });
    
  };
  const onRetry = () => {
    
    if (fileNameDownload && fileIdDownload) {
      downloadDataFile(fileNameDownload, fileIdDownload);
    }
  };
  const filtredRecentFiles = async (body: RecentDataType) => {
    if (!body?.end_date && !body?.start_date && !body?.tag) {
      setLoadingFilterReset(true);
    } else {
      setLoadingFilter(true);
    }

    const data = await getRecentFiles(body);
    const files = data?.result?.message;
    const recentFilesListConvert = files?.length
      ? files.map((file: any) => ({
          content: file?.DocumentName,
          id: file?.DocumentID,
          file_name: file?.DocumentName,
          timestamp: file?.CreatedDateTime,
          blob_path: file?.blob_path,
        }))
      : [];

    dispatch({ type: 'recent_files', value: recentFilesListConvert });
    setLoadingFilter(false);
    setLoadingFilterReset(false);
    setOpenFilterModal(false);
    if (!body?.end_date && !body?.start_date && !body?.tag) {
      setIsFiltred(false);
    } else {
      setIsFiltred(true);
    }
  };
  useEffect(() => {
    searches.length && setChatsList(searches);
  }, [searches]);

  return {
    category,
    setCategory,
    chatsCategory,
    header: generateHeader(),
    chatsList,
    isInteractiveSearchTab,
    isDocumentRetrievalTab,
    isAddDocumentTab,
    documentsList,
    isModalOpen: openConfirmationModal.open,
    onCloseModal,
    onPreviewDialog,
    pdfData,
    recent_files,
    openFilterModal,
    toggleFilterModal,
    setOpenFilterModal,
    filtredRecentFiles,
    loadingFilter,
    loadingReset,
    isFiltered,
    items,
    loadingFiles,
    loadingDownload,
    onRetry
  };
};

export default useQueriesRightColumn;
