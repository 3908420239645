import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import PrivacyModal from 'src/modules/Authentication/components/Privacy';
import Right from 'src/modules/Authentication/components/Right';
import TermsModal from 'src/modules/Authentication/components/TermsModal';
import Title from 'src/modules/Authentication/components/Title';
import Loading from 'src/modules/General/components/Loading';
import Logo from 'src/modules/General/components/Logo';
import Input from 'src/modules/General/components/Input';
import { isValidEmail } from 'src/core/utilities/validations';
import CheckIcon from 'src/assets/images/Queries/check.svg';
import CountdownTimer from 'src/modules/Authentication/components/CountDownTimer';
import {
  checkUserStatus,
  directRegister,
  resendVerificationCode,
  UserInfo,
  verify,
  VerifyAccount,
} from 'src/modules/General/api';
import Snackbar from 'src/modules/General/components/Snackbar';
import { calcDiffTimes } from 'src/core/utilities/general';

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState<number>(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [check, setCheck] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [time, setTime] = useState(120);
  const resetForm = () => {
    setFirstName('');
    setLastName('');

    setPassword('');
    setConfirmPassword('');
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    switch (step) {
      case 0:
        if (!isValidEmail(email)) {
          setErrorMessage('Please enter a valid email address.');
          return;
        }
        checkEmail();

        setErrorMessage('');
        resetForm();
        break;
      case 1:
        if (firstName === '' || lastName === '' || password === '') {
          setErrorMessage('Please fill in all the fields.');
          return;
        } else if (password !== confirmPassword) {
          setErrorMessage('Passwords mismatch.');
          return;
        }
        sendInfo();
        break;
      default:
        console.log(':)');
    }
  };
  const checkEmail = async () => {
    setIsLoading(true);
    const statusResponse = await checkUserStatus(email);
    setIsLoading(false);
    if (typeof statusResponse !== 'string') {
      if (statusResponse.error_code === 0) {
        resetForm();
        setMessage('');
        setStep(1);
      } else {
        if (statusResponse.response.status === 'active') {
          setShowSnackbar(true);
          setMessageType('success');
          setMessage(statusResponse.response.message);
          setTimeout(() => {
            navigate('/user-login');
          }, 3000);

          return;
        } else if (statusResponse.response.status === 'pending_verification') {
          setMessageType('success');
          setShowSnackbar(true);
          setMessage(statusResponse.response.message);
          if (!statusResponse.response.code_still_valid) {
            await resendVerificationCode(email);
          }
          const time = calcDiffTimes(statusResponse.response.expiration_date ?? '');

          setStep(2);
          return;
        } else if (statusResponse.error_code === 89) {
          setShowSnackbar(true);
          setMessageType('info');
          setMessage(statusResponse.response.message);
          resetForm();
          setStep(1);
        } else {
          setShowSnackbar(true);
          setMessageType('error');
          setMessage(statusResponse.response.message);
        }
      }
    }
  };
  const sendInfo = async () => {
    setIsLoading(true);

    const body: UserInfo = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Password: password,
      Username: email,
    };
    const response = await directRegister(body);
    if (typeof response !== 'string') {
      if (response.error_code === 0) {
        setMessageType('success');
        setShowSnackbar(true);
        setMessage(response.response.Message ?? '');
        const diffTime = calcDiffTimes(response.response.Expiration_Date ?? '');

        setStep(2);
      } else {
        setMessageType('error');
        setShowSnackbar(true);
        setMessage(response.response.Message ?? '');
      }
    }
    setIsLoading(false);
  };
  const verifyAccount = async () => {
    setIsLoading(true);
    const body: VerifyAccount = {
      email: email,
      code: verificationCode,
    };
    const verifyResponse = await verify(body);

    setIsLoading(false);
    if (typeof verifyResponse !== 'string') {
      setShowSnackbar(true);
      if (verifyResponse.error_code === 0) {
        setMessageType('success');
        setMessage(verifyResponse?.response?.Message);
        setErrorMessage('');
        navigate('/user-registered');
      } else {
        setMessageType('error');
        setMessage(verifyResponse?.response?.Message);
      }
    }
  };
  const resendCode = async () => {
    setIsLoadingResend(true);
    const code = await resendVerificationCode(email);
    if (typeof code !== 'string') {
      setShowSnackbar(true);
      if (code.error_code === 0) {
        setMessageType('success');
        setMessage(code.response.Message ?? '');
        setTime(120);
      } else {
        setMessageType('error');
        setMessage(code.response.Message ?? '');
      }
    }
    setIsLoadingResend(false);
  };
  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleOpenTermsModal = (e: React.FormEvent) => {
    e.preventDefault();
    setOpenTermsModal(true);
  };
  const handleOpenPrivacyModal = (e: React.FormEvent) => {
    e.preventDefault();
    setOpenPrivacyModal(true);
  };
  return (
    <div className="w-full h-full flex flex-row bg-white">
      <div className="w-5/12 grid items-center justify-center p-8 overflow-y-auto max-md:w-full max-sm:px-2">
        <div className="max-w-640 h-auto flex  flex-col gap-y-8 max-xs:w-full">
          <Logo />
          <Title title="Welcome to DeedDex" />
          {step !== 3 && (
            <>
              <form onSubmit={handleSubmit} className="min-w-80  flex flex-col justify-start gap-y-4 max-xs:w-full">
                {step === 0 && (
                  <>
                    <Input
                      title="Email Address"
                      type="text"
                      id="Email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      placeholder="Email"
                      inputStyle="rounded-lg"
                    />
                    {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
                    <button
                      disabled={isLoading}
                      type="submit"
                      className={`w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight disabled:bg-slate-300`}>
                      {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                      Check Email Account
                    </button>
                  </>
                )}
                {step === 1 && (
                  <>
                    <Input
                      title="First Name"
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.currentTarget.value)}
                      placeholder="First Name"
                      inputStyle="rounded-lg"
                    />
                    <Input
                      title="Last Name"
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.currentTarget.value)}
                      placeholder="Last Name"
                      inputStyle="rounded-lg"
                    />
                    {/* <Input
                      title="UserName"
                      type="text"
                      id="userName"
                      value={username}
                      onChange={(e) => setUsername(e.currentTarget.value)}
                      placeholder="UserName"
                      inputStyle="rounded-lg"
                    /> */}
                    <Input
                      title="Email Address"
                      type="text"
                      id="Email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      placeholder="Email"
                      inputStyle="rounded-lg"
                      disable
                    />
                    <Input
                      eyeIcon={true}
                      title="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      value={password}
                      containerStyle="relative"
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      handleTogglePassword={handleTogglePassword}
                      placeholder="Enter your password (8+ chars, mix of letters & numbers)"
                      inputStyle="rounded-lg"
                    />
                    <Input
                      eyeIcon={true}
                      title="Confirm Password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      containerStyle="relative"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                      handleTogglePassword={handleToggleConfirmPassword}
                      placeholder="Confirm Password"
                      inputStyle="rounded-lg"
                    />
                    {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
                    <button className="bg-transparent border-none text-primary" onClick={() => setStep(0)}>
                      Change Email Account
                    </button>
                    <div className="w-full  flex items-center justify-normal gap-x-1">
                      <input
                        type="checkbox"
                        checked={check}
                        id="terms"
                        name="terms"
                        onChange={(e) => {
                          {
                            setCheck(e.target.checked);
                          }
                        }}
                        value="terms"
                      />

                      <p className="text-base">
                        I Agree These{' '}
                        <button className="bg-transparent border-none text-primary" onClick={handleOpenTermsModal}>
                          {' '}
                          Terms and Conditions
                        </button>{' '}
                        and{' '}
                        <button onClick={handleOpenPrivacyModal} className="bg-transparent border-none text-primary">
                          {' '}
                          Privacy Policy
                        </button>{' '}
                      </p>
                    </div>
                    <button
                      disabled={isLoading || !check}
                      type="submit"
                      className={`w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight disabled:bg-slate-300`}>
                      {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                      Register
                    </button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <p>
                      The verification code was sent to &nbsp;
                      <span className="text-large font-bold">{email}</span>
                    </p>
                    <Input
                      title="Verification Code"
                      type="text"
                      id="verificationCode"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.currentTarget.value)}
                      placeholder="Enter Verification Code"
                      inputStyle="rounded-lg"
                    />

                    <button className="bg-transparent border-none text-primary" onClick={() => setStep(0)}>
                      Change Email Account
                    </button>
                    {!isLoadingResend && <CountdownTimer initialSeconds={time} action={resendCode} />}
                    {/* {message && <p className="text-green-500 text-center">{message}</p>} */}
                    <button
                      onClick={verifyAccount}
                      disabled={isLoading}
                      type="submit"
                      className={`w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight disabled:bg-slate-300`}>
                      {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                      Verify Account
                    </button>
                  </>
                )}
              </form>

              <div ref={scrollRef} className="w-full flex items-center justify-center flex-row gap-x-1">
                <p>Already have an account?</p>
                <Link
                  href="/user-login"
                  className="text-primary text-base font-medium capitalize leading-tight no-underline">
                  Login
                </Link>
              </div>
            </>
          )}
        </div>
        <TermsModal open={openTermsModal} onClose={() => setOpenTermsModal(false)} />
        <PrivacyModal open={openPrivacyModal} onClose={() => setOpenPrivacyModal(false)} />

        <Snackbar color={messageType} open={showSnackbar} onClose={() => setShowSnackbar(false)} message={message} />
      </div>
      <div className="w-7/12 block max-md:hidden">
        <Right />
      </div>
    </div>
  );
};

export default Signup;
