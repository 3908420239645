import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from 'src/modules/Queries/containers/Tabs';
import InteractiveSearch from './InteractiveSearch';
import DocumentRetrieval from './DocumentRetrieval';
import Drawer from 'src/modules/Queries/containers/Drawer';
import { useTranslation } from 'react-i18next';

const LeftColumn: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const TabItems = [t('tabs.documentRetrieval'), t('tabs.interactiveSearch')];

  const RenderTabs: Record<string, React.ReactNode> = {
    '#document_retrieval': <DocumentRetrieval />,
    '#r%C3%A9cup%C3%A9ration_de_documents': <DocumentRetrieval />,
    '#interactive_search': <InteractiveSearch />,
    '#recherche_interactive': <InteractiveSearch />,
  };

  const handleTabSelect = (tab: string) => {
    navigate(`#${tab}`);
  };

  return (
    <div className="full h-full flex justify-start flex-col gap-y-4">
      <div className="hidden max-lg:block">
        <Drawer />
      </div>
      <Tabs tabs={TabItems} onSelectTab={handleTabSelect} selectedTab={hash} />
      <div className="flex content-normal justify-start flex-col gap-8 bg-transparent h-calc-48 overflow-y-auto">
        {hash ? RenderTabs[hash] : <DocumentRetrieval />}
      </div>
    </div>
  );
};

export default LeftColumn;
