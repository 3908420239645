import { DataTemporary, TemporaryData, TemporaryList } from './index.types';

const TemporaryVisa = ({ list }: TemporaryList) => {
  return (
    <div className="w-full h-full flex flex-col gap-2 pb-2 overflow-y-auto">
      {list?.map((temporary: TemporaryData) => (
        <div key={temporary?.section} className="w-full h-auto p-2 bg-white rounded-lg shadow-lg">
          <h2 className="text-center text-neutral-700 text-xl font-bold font-['Inter'] max-md:text-base max-sm:text-sm">
            {temporary?.section}
          </h2>
          {temporary?.data?.map((data: DataTemporary, index: number) => (
            <p
              key={index}
              className='text-neutral-950 text-lg font-medium font-["Inter"] max-md:text-sm max-sm:text-xs'>
              {data?.key} :{' '}
              <span className='text-neutral-700 text-base font-normal font-["Inter"] leading-tight max-sm:text-xs'>
                {data?.value !== '' ? data?.value : '---'}
              </span>
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TemporaryVisa;
