import { EmailContentProps } from './index.types';
import { formatDate } from 'src/core/utilities/general';
import BackIcon from 'src/assets/images/Emails/arrow-narrow-left.svg';
import { useTranslation } from 'react-i18next';

const EmailContent = ({ email, onBackClick }: EmailContentProps) => {
  const { t } = useTranslation();
  return (
    <section className="w-full h-full overflow-y-auto p-4">
      <div className="w-full h-37.5 mb-5 flex justify-between flex-col bg-white py-4 px-8 rounded-lg">
        <button
          onClick={onBackClick}
          className="w-24 h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium leading-tight">
          <img src={BackIcon} alt="back" />
          {t('Back')}
        </button>

        <div className="flex items-start justify-between flex-row">
          <p className="text-neutral-950 text-lg font-medium">
            {t('Sender')} : <span className="text-neutral-800 text-base font-medium">{email.sender}</span>
          </p>
          <p className="text-neutral-950 text-lg font-medium">
            {t('Date')} : <span className="text-neutral-800 text-base font-medium">{formatDate(email.date)}</span>
          </p>
        </div>
        <div className="flex items-start justify-between flex-row">
          <p className="text-neutral-950 text-lg font-medium">
            {t('Subject')} : <span className="text-neutral-800 text-base font-medium">{email.subject}</span>
          </p>
        </div>
      </div>
      <div className="w-full h-calc-170 bg-white rounded-lg py-4 px-8 overflow-y-auto">
      {email.contentRow && email.contentRow != null ?  <div dangerouslySetInnerHTML={{ __html: email.contentRow }} className="w-full"></div> : 
        email.content && email.content != null ?(
          <div>
 <div className="w-full">
   {email.content.split('\n').map((line, index) => (
     <p key={index} className="text-neutral-700 text-base font-normal leading-tight">
       {line}
     </p>
   ))}
 </div>
</div>
        ) : ""
        }
        {/* {email.contentRow ? (
          <div dangerouslySetInnerHTML={{ __html: email.contentRow }} className="w-full"></div>
        ) : (
          <div>
            <div className="w-full">
              {email.content.split('\n').map((line, index) => (
                <p key={index} className="text-neutral-700 text-base font-normal leading-tight">
                  {line}
                </p>
              ))}
            </div>
          </div>
        )} */}
      </div>
   
    </section>
  );
};
export default EmailContent;
