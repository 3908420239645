import request from 'src/core/api/axios';

interface Quotas {
  interactive: number;
  retrieval: number;
  users: number;
  emails: number;
  upload_docs: number;
  messages: number;
  pages: number;
}

interface SubscriptionData {
  subscription_period_end: number;
  quotas: Quotas;
}

interface ResponseDataTrial {
  error_code?: number;
  status?: boolean;
  message?: string;
  detail?: string;
  data?: SubscriptionData;
}

export async function trial(): Promise<ResponseDataTrial | string> {
  try {
    const { data } = await request.post<ResponseDataTrial>('/stripe/register/trial', {});
    console.log(data);
    return data;
  } catch (error) {
    return 'Falid Active Trial Subscription';
  }
}
