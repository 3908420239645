import UserIcon from 'src/assets/images/Queries/user.svg';
import NxdIcon from 'src/assets/images/Queries/NXD.svg';
import CopyIcon from 'src/assets/images/Queries/copy.svg';
import Loading from 'src/modules/General/components/Loading';
import { copyToClipboard, getNodeText } from 'src/core/utilities/texts';
import { MessageProps } from './index.types';
import styles from './index.module.scss';
import EmailIcon from 'src/assets/images/Emails/email.png';
import AnswerIcon from 'src/assets/images/Queries/answer.png';
import DocIcon from 'src/assets/images/Documents/file.svg';
import { useTranslation } from 'react-i18next';

import MessageService from './index.services';
import ContentEmailDialog from '../../containers/ContentEmailDialog';
import PreviewModal from '../../containers/PreviewModal';

const Message = ({ type, text, emails, files, loading = false }: MessageProps): JSX.Element => {
  const {
    openDialogEmail,
    closeDialogEmail,
    emailSelected,
    showContentEmail,
    isModalOpen,
    pdfData,
    loadingDownload,
    onRetry,
    onCloseModal,
    onPreviewDialog,
  } = MessageService();
  const isSender = type === 'user';
  const { t } = useTranslation();

  const recieverActions = () =>
    text && (
      <div className="w-full flex items-center justify-between gap-4 max-sm:block">
        <div className="flex items-center justify-center flex-row gap-x-2 pr-2 max-sm:justify-start max-sm:pb-1">
          {/* <Icon name="emoji-happy" />
          <Icon name="emoji-sad" /> */}
        </div>

        <button
          onClick={() => copyToClipboard(getNodeText(text))}
          className=" h-10 px-4 py-2 rounded-lg border border-zinc-600 justify-start items-center gap-2 inline-flex">
          <img src={CopyIcon} alt="copy" />

          <span className="text-zinc-600 text-base font-medium">{t('Copy')}</span>
        </button>
      </div>
    );

  const showResposeMessage = () =>
    loading ? (
      <Loading variant="dots" className={styles['loading']} />
    ) : (
      <>
        <div className="w-full rounded-lg bg-slate-100 shadow p-4">
          <p className="flex flex-row items-center gap-2 text-lg font-semibold justify-center mb-4">
            <img src={AnswerIcon} alt="Answer" className="w-9" />
            Answer
          </p>
          <p className="font-semibold">{text}</p>
        </div>

        {files && files?.length > 0 && (
          <div className="w-full rounded-lg bg-slate-100 shadow p-4">
            <p className="flex flex-row items-center gap-2 text-lg font-semibold justify-center mb-4">
              <img src={EmailIcon} alt="Email" className="w-9" />
              Related Documents
            </p>
            <div className=" w-full grid grid-cols-2 gap-2 max-md:grid-cols-1">
              {files?.map((file: any, index: number) => (
                <p key={index}>
                  <button
                    onClick={() => onPreviewDialog(file.name, file.file_id)}
                    className="w-full flex flex-row items-center justify-start gap-3 p-3 rounded shadow-lg bg-white cursor-pointer">
                    <img src={DocIcon} alt="DocId" className="w-7" />
                    {file.name}
                  </button>
                </p>
              ))}
            </div>
          </div>
        )}
        {emails && emails?.length > 0 && (
          <div className="w-full rounded-lg bg-slate-100 shadow p-4">
            <p className="flex flex-row items-center gap-2 text-lg font-semibold justify-center mb-4">
              <img src={EmailIcon} alt="Email" className="w-9" />
              Related Emails
            </p>
            <div className="w-full grid grid-cols-2 gap-2 max-md:grid-cols-1">
              {emails?.map((email: any, index: number) => (
                <p key={index}>
                  <button
                    onClick={() => showContentEmail(email)}
                    className="w-full flex flex-row items-center justify-start gap-3 p-3 rounded shadow-lg bg-white cursor-pointer">
                    <img src={EmailIcon} alt="Email" className="w-6" />
                    <span className="whitespace-nowrap overflow-hidden text-ellipsis">{email?.sender}</span>
                  </button>
                </p>
              ))}
            </div>
          </div>
        )}
      </>
    );

  return (
    <div className="flex justify-start gap-4 gap-x-2 max-sm:w-full">
      <div
        className={`${isSender ? 'bg-orange-500' : 'bg-primary'} w-10 h-10 p-2.5  rounded-full flex-col justify-center items-center gap-2.5 inline-flex`}>
        <img src={isSender ? UserIcon : NxdIcon} alt="you" />
      </div>
      <div className="flex flex-col justify-start gap-y-1 w-calc-40">
        <p>{isSender ? t('You') : 'DeedDex'}</p>
        <div
          className={`${isSender ? 'bg-white' : 'bg-blue-200'}   px-3.5 py-2.5  rounded-tr-lg rounded-bl-lg rounded-br-lg flex-col justify-center items-start gap-2 inline-flex text-neutral-950 text-base font-normal leading-tight max-sm:text-xs`}>
          {isSender ? text : showResposeMessage()}
          {!isSender && recieverActions()}
        </div>
      </div>
      <ContentEmailDialog email={emailSelected} open={openDialogEmail} onClose={closeDialogEmail} />
      <PreviewModal
        open={isModalOpen}
        onClose={onCloseModal}
        pdfData={pdfData}
        loading={loadingDownload}
        text={'Preview File'}
        onRetry={onRetry}
        buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onCloseModal }]}
      />
    </div>
  );
};

export default Message;
