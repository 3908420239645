import { createContext, useContext, useReducer } from 'react';

type StateType = {
  emails: {
    id: number;
    sender: string;
    date: string;
    subject: string;
    content: string;
    contentRow: string;
    attachment: string;
  }[];
  showContent: boolean;
  loading: boolean;
  nextPage: boolean;
  loadingNextPage: boolean;
  loadingAccounts: boolean;
  emailAddress: string;
  count: number;
  pageSize: number;
};

type ActionType =
  | { type: 'emails'; value: StateType['emails'] }
  | { type: 'showContent'; value: StateType['showContent'] }
  | { type: 'emailAddress'; value: StateType['emailAddress'] }
  | { type: 'count'; value: StateType['count'] }
  | { type: 'loading'; value: StateType['loading'] }
  | { type: 'loadingAccounts'; value: StateType['loadingAccounts'] }
  | { type: 'loadingNextPage'; value: StateType['loadingNextPage'] }
  | { type: 'nextPage'; value: StateType['nextPage'] }
  | { type: 'pageSize'; value: StateType['pageSize'] };

const initialState = {
  emails: [],
  showContent: false,
  emailAddress: '',
  count: 0,
  pageSize: 20,
  loading: false,
  loadingAccounts: false,
  nextPage: false,
  loadingNextPage: false,
};

const EmailsContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const EmailsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <EmailsContext.Provider value={{ state, dispatch }}>{children}</EmailsContext.Provider>;
};

export const useEmailsContext = () => useContext(EmailsContext);

const mainReducer = (state: StateType, action: ActionType) => {
  
  switch (action.type) {
    case 'emails': {
      return { ...state, emails: action.value };
    }
    case 'showContent': {
      return { ...state, showContent: action.value };
    }
    case 'emailAddress':
      return { ...state, emailAddress: action.value };
    case 'count':
      return { ...state, count: action.value };
    case 'pageSize':
      return { ...state, pageSize: action.value };
    case 'loading':
      console.log('ddddd')
      return { ...state, loading: action.value };
    case 'nextPage':
      return { ...state, nextPage: action.value };
    case 'loadingAccounts':
      return { ...state, loadingAccounts: action.value };
    case 'loadingNextPage':
      return { ...state, loadingNextPage: action.value };
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
};
