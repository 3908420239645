import { setAuthToken } from 'src/core/api/axios';
import request from 'src/core/api/axios';

export interface Tag {
  tag_name: string;
  count: number;
}
export interface TagList {
  tags_list: Tag[];
}
interface LoginDataType {
  email: string;
  password: string;
}

interface LoginRespond {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

interface ForgetPasswordDataType {
  email: string;
}
interface ResetPasswordDataType {
  token: string;
  new_password: string;
}

interface IntrospectRespond {
  active: boolean;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  user_role: string;
  exp: number;
}

interface RegisterDataType {
  user: {
    Username: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
  };
  token: string;
}

interface RegisterRespond {
  Message: string;
}

interface ForgetPasswordRespond {
  message: string;
}

type NotificationDataType = {
  email: string;
  phone_number: string;
  method: 'sms';
};
export interface InviteData {
  Email: string;
  FirstName: string;
  LastName: string;
}
export interface UserStatus {
  error_code: number;
  response: {
    status: string;
    message: string;
    code_still_valid?: boolean;
    expiration_date?: string;
  };
}
export interface ResendCodeData {
  error_code: number;
  response: {
    Message?: string;
  };
}
export interface UserInfo {
  Username: string;
  Password: string;
  Email: string;
  FirstName: string;
  LastName: string;
}
export interface DirectRegisterResponse {
  error_code: number;
  response: responseRegister;
}
interface responseRegister {
  Username?: string;
  Email?: string;
  Message?: string;
  Expiration_Date?: string;
}
export interface VerifyAccount {
  email: string;
  code: string;
}
export interface VerifyAccountResponse {
  error_code: number;
  response: {
    Message: string;
  };
}
interface InviteRespond {
  error_code: number;
  Message: string;
}

export interface UserSubscriptionDataModel {
  active: boolean;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  username?: string;
  customer_id?: string;
  subscription_id?: string;
  subscription_period_end?: number;
  subscription_status?: string;
  exp: number;
  roles?: string[];
  quotas?: {
    interactive: number;
    document_retrieval: number;
    users: number;
    email_accounts: number;
    total_free_pages: number;
    doc_length: number;
    num_primary_email_msgs: number;
  };
}

export async function login(formData: LoginDataType): Promise<LoginRespond> {
  const { data } = await request.post<LoginRespond>('/login', formData, {
    headers: {},
    // withCredentials: true
  });

  setAuthToken('Bearer', data.access_token);
  // setAuthToken(data.token_type, data.access_token);
  return data;
}
export async function checkUserSubscription(): Promise<UserSubscriptionDataModel | string> {
  try {
    const { data } = await request.get<UserSubscriptionDataModel>('/access/subscription');

    return data;
  } catch (error) {
    return 'Error Fetch Subscription user';
  }
}
export async function checkUserStatus(email: string): Promise<UserStatus | string> {
  try {
    const { data } = await request.post<UserStatus>('/check-user-status', { email });
    return data;
  } catch (error) {
    return 'Error';
  }
}
export async function resendVerificationCode(email: string): Promise<ResendCodeData | string> {
  try {
    const { data } = await request.post<ResendCodeData>('/resend-verification', { email });
    return data;
  } catch (error) {
    return 'Error';
  }
}
export async function directRegister(body: UserInfo): Promise<DirectRegisterResponse | string> {
  try {
    const { data } = await request.post<DirectRegisterResponse>('/direct-register', body);
    return data;
  } catch (error) {
    console.log(error);
    return 'Error';
  }
}
export async function verify(body: VerifyAccount): Promise<VerifyAccountResponse | string> {
  try {
    const { data } = await request.post<VerifyAccountResponse>('/verify-account', body);
    return data;
  } catch (error) {
    console.log(error);
    return 'Error';
  }
}
export async function InviteLink(body: InviteData) {
  try {
    const { data } = await request.post<InviteRespond>('/add-to-whitelist', body, {
      headers: {},
    });
    return { data };
  } catch (error) {
    console.log(error);
  }
}

export async function introspect(): Promise<IntrospectRespond> {
  const { data } = await request.post<IntrospectRespond>('/introspect', {});
  return data;
}

export async function register(formData: RegisterDataType): Promise<RegisterRespond> {
  const { data } = await request.post<RegisterRespond>('/register', formData, {
    headers: {},
  });
  return data;
}
export async function forgetPassword(formData: ForgetPasswordDataType): Promise<ForgetPasswordRespond> {
  const { data } = await request.post<ForgetPasswordRespond>('/forget-password', formData, { headers: {} });
  return data;
}

export async function resetPassword(formData: ResetPasswordDataType): Promise<ForgetPasswordRespond> {
  const { data } = await request.post<ForgetPasswordRespond>('/reset-password', formData, { headers: {} });
  return data;
}

export async function invite(email: string): Promise<any> {
  const { data } = await request.post<any>('/invite', { email });
  return data;
}

//Notification
export async function notification(formData: NotificationDataType): Promise<any> {
  const { data } = await request.post<any>('/subscribe', formData);
  return data;
}

export async function refreshToken(refresh_token: string): Promise<any> {
  try {
    const { data } = await request.post<LoginRespond>('/refresh-token', { refresh_token });
    return data;
  } catch (error) {
    return 'Invalid refresh token';
  }
}
export async function fetchTags(): Promise<TagList | any> {
  try {
    const { data } = await request.get<TagList>('/tags');

    return data;
  } catch (error) {
    return 'Error Fetch Tags';
  }
}
