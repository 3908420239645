import CopyIcon from 'src/assets/images/Queries/copy.svg';
import SaveIcon from 'src/assets/images/General/save.svg';
import VoiceIcon from 'src/assets/images/Note/voice.svg';
import TrashIcon from 'src/assets/images/Note/trash.svg';
import StopIcon from 'src/assets/images/Note/stop.svg';
import EmptyNoteIcon from 'src/assets/images/Note/NoteEmpty.png';

import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import NoteServices from './insex.services';
import { copyToClipboard, getNodeText } from 'src/core/utilities/texts';
import { formatDate } from 'src/core/utilities/general';
import Header from 'src/modules/General/containers/Layout/Header';
import Loading from 'src/modules/General/components/Loading';
import Snackbar from 'src/modules/General/components/Snackbar';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import ShowMoreModal from 'src/modules/Note/components/ShowMoreModal';
import { useTranslation } from 'react-i18next';
import NoteActions from 'src/modules/Note/components/NoteAction';
import React from 'react';

const Notes = () => {
  const {
    note,
    setNote,
    onEdit,
    onListening,
    listening,
    deleteNoteMsg,
    onChangeLabel,
    noteList,
    itemSelected,
    addNewNote,
    updateNote,
    noteId,
    loading,
    loadingFetch,
    displaySnackbar,
    handleCloseSnackbar,
    openDeleteNoteModal,
    onDelete,
    closeDeleteNoteDialog,
    removeNote,
    openModalShowMore,
    openShowMoreModal,
    closeModalShowMore,
    noteContent,
    noteDate,
    supported,
    selectedNotes,
    handleSelectAll,
    handleSelectNote,
    onDeleteMultiple
  } = NoteServices();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="w-sideBar h-full px-4 py-8  flex flex-col gap-4  bg-Gray-ED overflow-hidden max-lg:h-calc-120 max-lg:w-full">
        {displaySnackbar.display && (
          <Snackbar
            color={displaySnackbar.color}
            open={displaySnackbar.display}
            onClose={handleCloseSnackbar}
            message={displaySnackbar?.message}
          />
        )}
        <div className="w-full flex flex-col gap-4">
          <div className="w-full">
            <div className="w-full flex flex-col relative">
              {listening && !loading && supported && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={onListening}>
                  <img src={StopIcon} alt="Stop" className="w-5 h-5" />
                </button>
              )}
              {!listening && (!note || loading) && supported && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={onListening}>
                   <img src={VoiceIcon} alt="Voice" className="w-5 h-5" />
                </button>
              )}
              {!listening && note && (
                <button
                  disabled={loading}
                  className="p-2 bg-gray-200 rounded-full absolute top-7 right-2"
                  onClick={deleteNoteMsg}>
                  <img src={TrashIcon} alt="Delete" className="w-5 h-5" />
                </button>
              )}

              <label className="text-neutral-950 text-sm font-normal">Note</label>
              <textarea
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder={t('Type here')}
                className="px-6 py-2.5 pr-12 bg-white rounded-lg  resize-none text-base font-normal leading-tight border-none outline-none"
                rows={6}></textarea>
              <button
                onClick={() => copyToClipboard(getNodeText(note))}
                className="p-2 bg-gray-200 rounded-full absolute bottom-2 right-2 z-30">
                <img src={CopyIcon} alt="Copy" />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-row items-end justify-between max-sm:block">
        
            <button
              onClick={noteId ? updateNote : addNewNote}
              disabled={loading || note == ''}
              className=" h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium capitalize leading-tight max-sm:w-full disabled:bg-gray-200">
              {loading && note && <Loading color="primary" size={20} variant="circle" />}
              <img src={SaveIcon} alt="Save" />
              {noteId && note ? t('Update Note') : t('Add Note')}
            </button>
          </div>
        </div>
        <Divider></Divider>
        
        <div className="w-full flex flex-col items-center justify-center rounded-lg overflow-y-auto">
        
          {loadingFetch && <Loading size={40} variant="circle" color="primary" />}
          {!noteList.length && !loadingFetch && (
            <div className='p-8 w-auto rounded bg-white flex flex-col gap-2 items-center justify-center shadow-xl'>
              <img src={EmptyNoteIcon} alt="Note" className='w-28' />
              <p className='text-lg'>
              You have not created a note yet.
              Create a note now.
              </p>
            </div>
          )}
          {noteList.length > 0 && (
            <section className='w-full h-full'>
            <div className="w-full py-1 px-3 bg-white flex justify-between items-center mb-1">
         
           <label className="flex items-center relative pl-8 mb-3 top-2 cursor-pointer text-sm select-none">
          <input
            type="checkbox"
            checked={noteList?.length > 0 && selectedNotes.length === noteList.length}
            onChange={handleSelectAll}
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span
            className={`absolute top-0 left-0 h-6 w-6 bg-gray-200 rounded transition-all duration-200
              ${noteList?.length > 0 && selectedNotes.length === noteList.length ? 'bg-orange-400' : 'bg-gray-200 hover:bg-gray-300'}`}
          >
            <span
              className={`absolute left-2 top-1 w-1.5 h-3.5 border-white border-r-2 border-b-2 transform
                ${noteList.length > 0 && selectedNotes.length === noteList.length ? 'block rotate-45' : 'hidden'}`}
            />
          </span>
          <span className='relative top-0.5'>
          {t('Select All Notes')}
          </span>
          
        </label>
          {selectedNotes.length > 0 && (
            <IconButton
            onClick={onDeleteMultiple}
            disabled={selectedNotes.length === 0} 
          >
             <img src={TrashIcon} alt="Trash" className="w-5 h-5" />
          </IconButton>
          )}
          
        </div>
        <TableContainer className="bg-white rounded-lg shadow">
              <Table aria-label="collapsible table">
              <TableHead>
      <TableRow>
        <TableCell>
        <span>{t('Note')}</span>
        </TableCell>
        <TableCell>{t('Created Date')}</TableCell>
        <TableCell></TableCell>
      </TableRow>
</TableHead>
<TableBody>
  {noteList?.map((note) => (
    <TableRow key={note.NoteID}>
      <TableCell className='flex items-center'>
      <label className="block relative pl-8 mb-3 top-2 cursor-pointer text-2xl select-none">
      <input
        type="checkbox"
        checked={selectedNotes.includes(note.NoteID)}
        onChange={() => handleSelectNote(note.NoteID)}
        className="absolute opacity-0 cursor-pointer h-0 w-0"
      />
      <span
        className={`absolute top-0 left-0 h-6 w-6 bg-gray-200 rounded transition-all duration-200
           ${selectedNotes.includes(note.NoteID) ? 'bg-orange-400' : 'bg-gray-200 hover:bg-gray-300'}`}
      >
        <span
          className={`absolute left-2 top-1 w-1.5 h-3.5 border-white border-r-2 border-b-2 transform
            ${selectedNotes.includes(note.NoteID) ? 'block rotate-45' : 'hidden'}`}
        />
      </span>
    </label>
      
        <span onClick={()=>openModalShowMore(note)} className='ml-2 relative -top-0.5 left-5 cursor-pointer'>{note.Note.length > 80 ? note.Note.substring(0, 81) + '...' : note.Note}</span>
      </TableCell>
      <TableCell>{formatDate(note.CreatedDateTime)}</TableCell>
      <TableCell align="right">
        <NoteActions onEdit={onEdit} onDelete={onDelete} note={note} />
      </TableCell>
    </TableRow>
  ))}
</TableBody>
              </Table>
            </TableContainer>
            </section>
            
          )}
        </div>
        <ConfirmationModal
          open={openDeleteNoteModal}
          onClose={closeDeleteNoteDialog}
          text={`Are you sure to delete ${selectedNotes.length > 1 ? 'notes?' : 'this note?'}`}
          buttons={[
            {
              children: t('Remove'),
              variant: 'contained',
              color: 'error',
              onClick: removeNote,
              endIcon: loading && <Loading variant="circle" size={20} color="inherit" />,
            },
            {
              children: t('Cancel'),
              variant: 'contained',
              color: 'success',
              onClick: closeDeleteNoteDialog,
            },
          ]}
        />
        <ShowMoreModal open={openShowMoreModal} onClose={closeModalShowMore} note={noteContent} noteDate={noteDate} />
      </div>
    </>
  );
};

export default Notes;
