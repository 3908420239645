import Message from 'src/modules/Queries/components/Message';
import { MessageType } from 'src/modules/Queries/components/Message/index.types';
import { ChatsProps } from './index.types';
import styles from './index.module.scss';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

const Chats = ({ list, autoScroll }: ChatsProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [autoScroll]);

  return (
    <div className="w-full pr-2">
      <div className="flex items-start flex-col gap-y-4">
        {list.map((msg) => {
          return (
            (msg?.loading || msg.text) && (
              <div key={msg.type + msg.id} className="max-sm:w-full">
                {/* <div className={cn(styles['info'], msg.type === 'user' && styles['info__user'])}>
                  {generateName(msg.type)}
                  {msg?.time && <span className={styles['info__time']}>{msg.time}</span>}
                </div> */}
                <div>
                  <Message
                    files={msg.files ?? []}
                    emails={msg.emails ?? []}
                    id={msg.id}
                    img={msg?.img || ''}
                    type={msg.type as MessageType}
                    text={msg.text}
                    loading={msg?.loading}
                  />
                </div>
              </div>
            )
          );
        })}
        <div ref={scrollRef} />
      </div>
    </div>
  );
};
export default Chats;
