import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'src/core/contexts/_app.context';
import { useQuereisContext } from 'src/core/contexts/queries.context';
import { sortViaProperty } from 'src/core/utilities/general';
import { checkUserSubscription, fetchTags, Tag } from 'src/modules/General/api';
import { SearchDataType, SearchMethod, getRecentSearches, search } from 'src/modules/Queries/api';
import { ChatsProps } from 'src/modules/Queries/containers/Chats/index.types';

export type UploadedFilesType = { id: number; name: string; files?: File | string; type: 'screenshot' | 'file' }[];
export type MessageType = ChatsProps['list'];
export interface Item {
  label: string;
  value: string;
}

const useInteractiveSearch = () => {
  const { state, dispatch } = useAppContext();
  const { dispatch: queriesDispatch } = useQuereisContext();
  const { screenshotMode, subscription } = state || {};
  const [searchValue, setSearchValue] = useState('');
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [methodSearch, setMethodSearch] = useState('');
  const [files, setFiles] = useState<UploadedFilesType>([]);
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [autoScroll, setAutoScroll] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [messeageSubscription, setMessageSubscription] = useState<string>('');
  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [tagSelected, setTagSelected] = useState<string[]>([]);
  const [concatFiles, setConcatFiles] = useState(false);
  const [questions, setQuestions] = useState<MessageType>([]);
  const [answers, setAnswers] = useState<MessageType>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [relatedEmails, setRelatedEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const lastFileId = files[files.length - 1]?.id || 0;
  const screenshotTypeList = files.filter((file) => file.type === 'screenshot');
  const lastScreenshotNumber = screenshotTypeList.length || 0;
  const attachedFilesNames = attachedFiles.map((file) => file.name);
  const chats = sortViaProperty([...questions, ...answers], 'id', 'up');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleGoToSettings = () => {
    setIsDialogOpen(false);
    navigate('/settings');
  };
  const emptyQuestionAnswer =
    'It seems like your message is blank. How can I assist you today? Feel free to ask any questions or share any information you would like help with!';

  const onCloseModal = () => setOpenConfirmationModal({ ...openConfirmationModal, open: false });

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const changeMethodSearch = (method: string) => {
    setMethodSearch(method);
  };

  const changeTag = (value: Item[]) => {
    setItemSelected(value);
    setTagSelected(value.map((item) => item.value));
  };
  const formData = (method: SearchMethod) => {
    const data: SearchDataType = { question: searchValue };
    if (methodSearch) {
      data.search_source = methodSearch;
    } else {
      data.search_source = 'all';
    }
    if (fromDate && toDate) {
      data.start_date = fromDate.format('YYYY-MM-DD');
      data.end_date = toDate.format('YYYY-MM-DD');
    }
    if (tagSelected.length > 0) {
      data.tag = tagSelected;
    }
    return data;
  };

  const getTags = async () => {
    const data = await fetchTags();
    if (typeof data !== 'string') {
      const tags: Item[] = (await data.tags_list?.length)
        ? data.tags_list.map((tag: Tag) => ({
            label: tag.tag_name,
            value: tag.tag_name,
          }))
        : [];

      setItems(tags);
    }
  };

  const onSendQuestion = async () => {
    if (subscription?.subscription_status === 'expired_or_inactive') {
      setMessageSubscription('Please renew or subscribe to a plan! ');
      // setShowSnackbar(true);
      setIsDialogOpen(true);
    } else {
      if (subscription?.quotas && subscription?.quotas?.interactive > 0) {
        setIsDialogOpen(false);
        setShowSnackbar(false);
        setIsLoading(true);
        setRelatedEmails([]);
        if (searchValue.trim()) {
          setQuestions([
            ...questions,
            { id: questions.length + 1, type: 'user', text: searchValue, question: searchValue },
          ]);
          setAnswers([
            ...answers,
            { id: answers.length + 1, type: 'bot', text: '', loading: true, question: searchValue },
          ]);
          try {
            setAutoScroll(true);
            const { answer_web, doc_ids, files_names, list_related_docs_content, error_code, error_message } =
              await search(formData(SearchMethod.Normal), SearchMethod.Normal);
            const { result: recentSearches } = await getRecentSearches({ number_of_records: 400, page_size: 400 });
            let emails = [];
            if (error_code === 0) {
              if (list_related_docs_content.email?.content?.length > 0) {
                emails = list_related_docs_content?.email.content?.map((content: string, index: number) => ({
                  sender: list_related_docs_content?.email?.sender[index],
                  subject: list_related_docs_content?.email?.subject[index],
                  date: list_related_docs_content?.email?.email_datetime[index],
                  content: list_related_docs_content?.email?.content[index],
                }));
              }
              setRelatedEmails(emails);

              const documentsList =
                !!files_names?.length &&
                files_names.map((file: string, index: number) => ({
                  name: file,
                  link: file,
                  file_id: doc_ids[index],
                }));
              queriesDispatch({ type: 'documents', value: documentsList });
              setAnswers([
                ...answers,
                {
                  id: answers.length + 1,
                  type: 'bot',
                  text: answer_web,
                  emails: emails,
                  files: documentsList,
                  loading: false,
                  question: searchValue,
                },
              ]);
              setIsLoading(false);
              const searchesList = recentSearches?.length
                ? recentSearches.map((search: any) => ({
                    id: search.id,
                    title: search.question,
                    content: search.answer,
                    time: search.timestamp,
                    // emails: emails.length > 0 ? emails : [],
                  }))
                : [];
              queriesDispatch({ type: 'searches', value: searchesList });
            } else {
              setQuestions([
                ...questions,
                { id: questions.length + 1, type: 'user', text: searchValue, question: searchValue },
              ]);
              setAnswers([
                ...answers,
                { id: answers.length + 1, type: 'bot', text: error_message ?? '', question: searchValue },
              ]);
            }
            const userSubscription = await checkUserSubscription();
            if (typeof userSubscription !== 'string') {
              console.log(userSubscription);
              dispatch({ type: 'subscription', value: userSubscription });
              // setMessage('Your subscription has been activated.');
            }
            setSearchValue('');
            setFromDate(null);
            setToDate(null);
            setItemSelected([]);
            setIsLoading(false);
            setAutoScroll(false);
          } catch {
            setAnswers([
              ...answers,
              {
                id: answers.length + 1,
                type: 'bot',
                text: 'No response was found for your search.Please try again.',
                loading: false,
                question: searchValue,
              },
            ]);
            setIsLoading(false);
            setAutoScroll(false);
          }
        } else {
          setQuestions([
            ...questions,
            { id: questions.length + 1, type: 'user', text: searchValue, question: searchValue },
          ]);
          setAnswers([
            ...answers,
            { id: answers.length + 1, type: 'bot', text: emptyQuestionAnswer, question: searchValue },
          ]);
          setSearchValue('');
          setItemSelected([]);
          setIsLoading(false);
          setFromDate(null);
          setToDate(null);
          setAutoScroll(false);
          setIsDialogOpen(true);
        }
      } else {
        // setShowSnackbar(true);
        setIsDialogOpen(true);
        setMessageSubscription("You've reached your limit of Interactive Search. Upgrade for unlimited access.");
      }
    }
  };

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files?.length) {
      setAttachedFiles(Object.values(files));
      setOpenConfirmationModal({ type: 'file', open: true });
    }
  };

  const onScreenshotClick = () => dispatch({ type: 'screenshotMode', value: !screenshotMode });

  const onTakeScreenshot = (newLink: HTMLAnchorElement) => {
    {
      setLink(newLink);
      setOpenConfirmationModal({ type: 'screenshot', open: true });
    }
  };

  const onConfirmAttachment = () => {
    {
      if (openConfirmationModal.type === 'screenshot') {
        link?.download &&
          setFiles([...files, { id: lastFileId + 1, name: link?.download, files: link?.href, type: 'screenshot' }]);
      } else {
        let temp: UploadedFilesType = [];
        for (let i = 0; i < attachedFiles.length; i++) {
          temp = [
            ...temp,
            { id: lastFileId + 1 + i, name: attachedFiles[i].name, files: attachedFiles[i], type: 'file' },
          ];
          setFiles([...files, ...temp]);
        }
      }
      onCloseModal();
      setLink(null);
      setAttachedFiles([]);
    }
  };

  const onDeleteAddedFile = (fileId: number) => {
    const removedFiles = files.filter((file) => file.id !== fileId);
    setFiles(removedFiles);
  };

  const onConcatFiles = (checked: boolean) => {
    setConcatFiles(checked);
  };

  const onUploadFiles = async () => {
    setIsLoading(true);
    try {
      // await analyzer({
      //   files: uploadFiles,
      //   concatenate: concatFiles,
      //   files_names: uploadFilesNames,
      // });
      // setFiles([]);
      setConcatFiles(false);
    } catch {}
    setIsLoading(false);
  };
  useEffect(() => {
    getTags();
  }, []);

  return {
    files,
    searchValue,
    onSearchInputChange,
    onSendQuestion,
    onInputFileChange,
    onScreenshotClick,
    screenshotMode,
    lastScreenshotNumber,
    onTakeScreenshot,
    preview: link?.href || attachedFilesNames,
    onConfirmAttachment,
    onDeleteAddedFile,
    onConcatFiles,
    onUploadFiles,
    chats,
    isLoading,
    isModalOpen: openConfirmationModal.open,
    onCloseModal,
    changeMethodSearch,
    methodSearch,
    changeTag,
    itemSelected,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    autoScroll,
    relatedEmails,
    messeageSubscription,
    setShowSnackbar,
    showSnackbar,
    handleClose,
    handleGoToSettings,
    isDialogOpen,
  };
};

export default useInteractiveSearch;
