import EmailsList from 'src/modules/Emails/containers/EmailsList';
import EmailAuthModal from 'src/modules/Emails/containers/EmailAuthModal';
import Snackbar from 'src/modules/General/components/Snackbar';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import useEmailsLeftColumn from './index.services';
import Loading from 'src/modules/General/components/Loading';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';
import { AddEmailsDataType } from 'src/modules/Emails/api';
import { SnackbarProps } from '../HeaderMenu';
import { useTranslation } from 'react-i18next';
import EmailChangePasswordModal from 'src/modules/Emails/containers/EmailChangePassword';
import { ChangeEmailsDataType } from 'src/modules/Emails/containers/EmailChangePassword/index.types';
interface RightEmailProps {
  emailsList: EmailProps[];
  isLoading: boolean;
  openEmailModal: boolean;
  onRemoveEmailClick: (email: string) => void;
  onRemoveEmail: () => Promise<void>;
  updateEmailsList: (call: boolean, processCal:boolean) => Promise<void>;
  onAddEmail: (formData: AddEmailsDataType) => Promise<void>;
  setOpenEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  openConfirmationModal: {
    name: 'remove' | 'deactivate';
    open: boolean;
  };
  closeConfirmationModal: () => void;
  showSnackbar: SnackbarProps;
  handleCloseSnackbar: () => void;
  onDeactivateEmail: () => Promise<void>;
  onActiveOrDeactiveEmail: (isActive: boolean, email: string) => Promise<void>;
  isRemoveAction: boolean;
  messageConfirmationModal:string;
  isOpenConfirmationModal: boolean;
  setOpenPasswordModal: React.Dispatch<React.SetStateAction<boolean>>
  openChangePasswordModal: boolean,
  changePasswordEmail: (formData: ChangeEmailsDataType) => void,
  handleOpenCahngePasswordModal: (address: string, host: string) => void,
  handleCloseChangePasswordEmailModal: () => void,
  emailAddress:string;
  host: string
}
const RightColumn = ({
  emailsList,
  isLoading,
  showSnackbar,
  onAddEmail,
  onRemoveEmail,
  onRemoveEmailClick,
  updateEmailsList,
  handleCloseSnackbar,
  openEmailModal,
  setOpenEmailModal,
  openConfirmationModal,
  closeConfirmationModal,
  onActiveOrDeactiveEmail,
  onDeactivateEmail,
  isRemoveAction,
  messageConfirmationModal,
  isOpenConfirmationModal,
  openChangePasswordModal,
  setOpenPasswordModal,
  changePasswordEmail,
  handleCloseChangePasswordEmailModal,
  handleOpenCahngePasswordModal,
  emailAddress,
  host
}: RightEmailProps) => {
  const { t } = useTranslation();
  const { onEmailClick,setIsLoading } = useEmailsLeftColumn();
 
  // const isRemoveAction = openConfirmationModal.name === 'remove';
  // const messageConfirmationModal = `${t('Are you sure to')} ${openConfirmationModal.name} ${t('this Email Account')}?`;

  return (
    <>
      <div className="w-full h-full py-8 px-3 bg-white border-l border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
        <h3 className="text-neutral-950 text-xl font-bold">{t('My Email Accounts')}</h3>
        {isLoading ? (
          <div className="w-full h-full flex items-start justify-center">
            <Loading size={40} variant="circle" />
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-2 items-center justify-between flex-row gap-x-4">
              <button
                onClick={() => {
                  setOpenEmailModal(true);
                  setIsLoading(false);
                }}
                className="h-11 px-1 py-3 bg-blue-600 rounded-xl shadow justify-center items-center inline-flex text-white text-sm font-medium leading-tigh">
                {t('Add Email Account')}
              </button>
              <button
                onClick={() => updateEmailsList(false,true)}
                className="h-11 px-1 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center inline-flex text-blue-600 text-sm font-medium leading-tight">
                {t('Sync Manually')}
              </button>
            </div>
            <EmailsList
              emails={emailsList}
              onEmailClick={onEmailClick}
              onRemoveEmail={onRemoveEmailClick}
              onActiveOrDeactiveEmail={onActiveOrDeactiveEmail}
              onChangePassword={handleOpenCahngePasswordModal}
            />
          </>
        )}
      </div>
      <EmailAuthModal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmitAddEmail={onAddEmail}
        isLoading={isLoading}
      />
      <EmailChangePasswordModal
      open ={openChangePasswordModal}
      onClose={handleCloseChangePasswordEmailModal}
      onSubmitChangePasswordEmail={changePasswordEmail}
      isLoading = {isLoading}
      address={emailAddress}
      host={host}
      />
      {showSnackbar.display && (
        <Snackbar
          color={showSnackbar.color}
          open={showSnackbar.display}
          onClose={handleCloseSnackbar}
          message={showSnackbar?.message}
        />
      )}
      <ConfirmationModal
        open={isOpenConfirmationModal}
        onClose={closeConfirmationModal}
        text={messageConfirmationModal}
        buttons={[
          {
            children: isRemoveAction ? t('Remove') : t('Deactivate'),
            variant: 'contained',
            color: 'error',
            onClick: isRemoveAction ? onRemoveEmail : onDeactivateEmail,
            endIcon: isLoading && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeConfirmationModal,
          },
        ]}
      />
    </>
  );
};

export default RightColumn;
