import request from 'src/core/api/axios';

///////////////////////////// Subscriptions ////////////////////////
interface Attribute {
  count: number;
  recurring?: boolean;
}

interface Policy {
  resource: string;
  action: string;
  attribute?: Attribute;
}

interface Price {
  ID: number;
  SubscriptionID: number;
  Name: string;
  Recurring: boolean;
  Period: string;
  Amount: number;
  PriceID: string;
  DiscountID?: string | null;
  MainSubscriptionPrice: boolean;
  ResourceUnit?: number | null;
}

export interface Subscription {
  SubscriptionID: number;
  Name: string;
  Type: string;
  ProductID: string;
  AdditionalNotes: string;
  Features: any[];
  prices: Price[];
  policies: Policy[];
  role_name: string;
}

interface SubscriptionsResponseModel {
  error_code: number;
  status: boolean;
  message: string;
  data: Subscription[];
}

////////////////////////////////////////////////////////////////////

//////////////////////////// Response Trial////////////////////////
interface Quotas {
  interactive: number;
  retrieval: number;
  users: number;
  emails: number;
  upload_docs: number;
  messages: number;
  pages: number;
}

interface SubscriptionData {
  subscription_period_end: number;
  quotas: Quotas;
}

interface ResponseDataTrial {
  error_code?: number;
  status?: boolean;
  message?: string;
  detail?: string;
  data?: SubscriptionData;
}
///////////////////////////////////////////////////////////////////

/////////////////////////// Plan Interface ////////////////////////
export interface PlandDataType {
  name: string;
  subscriptionId: number;
  priceId: string;
  productId: string;
  amount: Amount[];
}
export interface Amount {
  period: string;
  amount: number;
}
//////////////////////////////////////////////////////////////////

///////////////////////// Purchase ///////////////////////////////
export interface ExtraItemType {
  price_id: number;
  quantity: number;
}

export interface PurchaseDataType {
  subscription_id: number;
  period: string;
  extra_items?: ExtraItemType[];
}
export interface PurchaseResponseType {
  url: string;
}
//////////////////////////////////////////////////////////////////

export async function subscriptions(): Promise<SubscriptionsResponseModel | string> {
  try {
    const { data } = await request.get<SubscriptionsResponseModel>('/subscription', {
      headers: {},
    });
    return data;
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      console.error('Status Code:', error.response.status);
      console.error('Error Data:', error.response.data);
      return error.response?.data?.detail ?? '';
    } else {
      console.error('Error:', error.message);
      return 'Failed To Get Subscriptions';
    }
  }
}
export async function purchase(body: PurchaseDataType): Promise<PurchaseResponseType | string> {
  try {
    const { data } = await request.post<PurchaseResponseType>('/stripe/checkout', body);
    console.log(data);
    return data;
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      console.error('Status Code:', error.response.status);
      console.error('Error Data:', error.response.data);
      return error.response?.data?.detail ?? '';
    } else {
      console.error('Error:', error.message);
      return 'Failed Active Trial Subscription';
    }
  }
}

export async function activeTrial(): Promise<ResponseDataTrial | string> {
  try {
    const { data } = await request.post<ResponseDataTrial>('/stripe/register/trial', {});
    console.log(data);
    return data;
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      console.error('Status Code:', error.response.status);
      console.error('Error Data:', error.response.data);
      return error.response?.data?.detail ?? '';
    } else {
      console.error('Error:', error.message);
      return 'Failed Active Trial Subscription';
    }
  }
}
