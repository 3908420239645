import { useState } from 'react';
import Modal from 'src/modules/General/components/Modal';
import { EmailAuthModalProps } from './index.types';
import Input from 'src/modules/General/components/Input';
import Loading from 'src/modules/General/components/Loading';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const EmailAuthModal = ({ open, onClose, onSubmitAddEmail, isLoading }: EmailAuthModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [host, setHost] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      host: host,
      address: address,
      password: password,
      activate: true,
      processed_emails_count: 0,
    };
    onSubmitAddEmail(data);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleSubmit(e);
  };
  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className=" bg-white flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full  flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-lg font-medium ">{t('Add Email')}</span>
          <span className="w-full text-neutral-950 flex items-center justify-normal gap-2 font-bold max-sm:text-xs">
            {t('How to Add Your Email to')} DeedDex:{' '}
            <div className="hidden max-sm:block">
          
              <a
                className="w-6 h-6 text-white cursor-pointer flex items-center justify-center rounded-full bg-blue-400 p-2" href="/instuction-add-email" target="_blank" rel="noopener noreferrer">
            !
              </a>
         
            </div>
            <div className="block max-sm:hidden">
             
              <a
               
                className="w-6 h-6 text-white cursor-pointer flex items-center justify-center rounded-full bg-blue-400 p-2" href="/instuction-add-email" target="_blank" rel="noopener noreferrer">
                
                !
              </a>
          
            </div>
          </span>
       
        </div>

        <form className="w-full flex items-center justify-normal flex-col gap-y-10 mt-4 " onSubmit={handleSubmit}>
          <div className="w-full flex items-center justify-center flex-col gap-y-2">
            <Input
              disable={isLoading}
              title={t('Host')}
              type="text"
              onChange={(e) => setHost(e.currentTarget.value)}
              value={host}
              placeholder={('Enter your Host')}
              containerStyle="gap-y-2"
              inputStyle="rounded-lg"
            />
            <Input
              disable={isLoading}
              title={t('Email')}
              type="email"
              onChange={(e) => setAddress(e.currentTarget.value)}
              value={address}
              placeholder={t('Enter Your Email')}
              containerStyle="gap-y-2"
              inputStyle="rounded-lg"
            />
            <Input
              disable={isLoading}
              title={t('Password')}
              eyeIcon={true}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.currentTarget.value)}
              value={password}
              handleKeyDown={handleKeyDown}
              handleTogglePassword={handleTogglePassword}
              placeholder={t('Enter Your Password')}
              containerStyle="gap-y-2 relative"
              inputStyle="rounded-lg"
            />
          </div>
          <div className="grid grid-cols-2 flex-row items-center justify-center  gap-3 max-sm:grid-col-s-1">
            <button
              onClick={() => onClose('closeButtonClick')}
              className="w-full h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium  capitalize leading-tight">
              {t('Cancel')}
            </button>
            <button
              disabled={isLoading}
              type="submit"
              className="h-11 px-4 py-3 bg-blue-600 rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium  capitalize leading-tight">
              {isLoading && <Loading variant="circle" />}
              {t('Sign in')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailAuthModal;
