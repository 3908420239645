import { useNavigate, useLocation } from 'react-router-dom';
import ButtonNavigation from 'src/modules/General/components/ButtonNavigation';
import QuerisIcon from 'src/assets/images/SideBar/queries.svg';
import QuerisIconActive from 'src/assets/images/BottomNavigation/queries-active.svg';
import InboxIcon from 'src/assets/images/SideBar/Email.svg';
import InboxIconActive from 'src/assets/images/BottomNavigation/Email-active.svg';
import FileIcon from 'src/assets/images/SideBar/myDocuments.svg';
import FileIconActive from 'src/assets/images/BottomNavigation/myDocuments-active.svg';

import NoteIcon from 'src/assets/images/SideBar/note.svg';
import NoteActiveIcon from 'src/assets/images/BottomNavigation/note-active.svg';

import AutoGenActiveIcon from 'src/assets/images/BottomNavigation/AutoGen-active.svg';
import AutoGenIcon from 'src/assets/images/SideBar/AutoGen.svg';
import { useTranslation } from 'react-i18next';

const BottomNavigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActiveRoute = (path: string) => pathname.includes(path);
  return (
    <div className="w-full h-16 bg-white flex items-center justify-between shadow-top rounded-t-xl">
      {/* {Navigations.map((route, index: number) => ( */}
      {/* <div className={`w-1/3`}> */}
      <ButtonNavigation
        width={14}
        height={14}
        classname={` w-full h-16 ${isActiveRoute('/documents') ? 'text-primary' : 'text-black '}  flex flex-col items-center justify-center text-xs border-r`}
        iconClass={`w-8 h-8 flex items-center justify-center bg-light-blue-3 p-1 rounded-lg cursor-pointer mb-2 ${isActiveRoute('/documents')}?'bg-primary shadow-active-route':''`}
        label={t('Documents')}
        onClick={() => navigate('/documents')}>
        <img className="w-6 h-6" src={isActiveRoute('/documents') ? FileIconActive : FileIcon} alt="documents" />
      </ButtonNavigation>
      <ButtonNavigation
        width={14}
        height={14}
        classname={` w-full h-16 ${isActiveRoute('/search') ? 'text-primary' : 'text-black '}  flex flex-col items-center justify-center text-xs border-r`}
        iconClass={`w-8 h-8 flex items-center justify-center bg-light-blue-3 p-1 rounded-lg cursor-pointer mb-2 ${isActiveRoute('/search')}?'bg-primary shadow-active-route':''`}
        label={t('Search')}
        onClick={() => navigate('/search')}>
        <img src={isActiveRoute('/search') ? QuerisIconActive : QuerisIcon} alt="search" />
      </ButtonNavigation>

      <ButtonNavigation
        width={14}
        height={14}
        classname={` w-full h-16 ${isActiveRoute('/my-emails') ? 'text-primary' : 'text-black '} flex flex-col items-center justify-center text-xs border-r`}
        label={t('Emails')}
        onClick={() => navigate('/my-emails')}>
        <img src={isActiveRoute('/my-emails') ? InboxIconActive : InboxIcon} alt="Emails" />
      </ButtonNavigation>
      <ButtonNavigation
        width={14}
        height={14}
        classname={` w-full h-16 ${isActiveRoute('/auto-Gen') ? 'text-primary' : 'text-black '}  flex flex-col items-center justify-center text-xs border-r`}
        label={t('AutoGen')}
        onClick={() => navigate('/auto-Gen')}>
        <img className="w-6 h-6" src={isActiveRoute('/auto-Gen') ? AutoGenActiveIcon : AutoGenIcon} alt="auto-Gen" />
      </ButtonNavigation>
      <ButtonNavigation
        width={14}
        height={14}
        classname={` w-full h-16 ${isActiveRoute('/note') ? 'text-primary' : 'text-black '}  flex flex-col items-center justify-center text-xs border-r`}
        label={t('Notes')}
        onClick={() => navigate('/note')}>
        <img src={isActiveRoute('/note') ? NoteActiveIcon : NoteIcon} alt="Note" />
      </ButtonNavigation>

      {/* </div> */}
      {/* ))} */}
    </div>
  );
};
export default BottomNavigation;
