import { useEffect, useState } from 'react';
import { useAppContext } from 'src/core/contexts/_app.context';
import { SearchDataType, SearchMethod, downloadFile, downloadRecentFile, search } from 'src/modules/Queries/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { checkUserSubscription, fetchTags, Tag } from 'src/modules/General/api';
import { EmailDataType } from 'src/modules/Queries/containers/ContentEmailDialog/index.types';
type UploadedFilesType = { id: number; name: string; files?: File | string; type: 'screenshot' | 'file' }[];
type LoadingType = { name: 'question' | 'upload'; loading: boolean };
export interface Item {
  label: string;
  value: string;
}

const useDocumentRetrieval = () => {
  const { hash } = useLocation();
  const [reset, setReset] = useState<boolean>(false);
  const { state, dispatch } = useAppContext();
  const { screenshotMode, subscription } = state || {};
  const [searchValue, setSearchValue] = useState('');
  const [methodSearch, setMethodSearch] = useState('');
  const [searchResultOf, setSearchResultOf] = useState('');
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [tagSelected, setTagSelected] = useState<string[]>([]);
  const [files, setFiles] = useState<UploadedFilesType>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);
  const [emailSelected, setEmailSelected] = useState<EmailDataType>();
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [loadingDaownload, setLoadingDownload] = useState(false);
  const [fromDateSelected, setFromDateSelected] = useState<string>('');
  const [toDateSelected, setToDateSelected] = useState<string>('');
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [relatedEmails, setRelatedEmails] = useState([]);
  const [showMessage, setShowMessage] = useState('');
  const [filename, setFilename] = useState<string>('');
  const [documentId, setDocumentId] = useState<number>(0);
  const [openDialogEmail, setOpenDialogEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<LoadingType>({ name: 'question', loading: false });
  const lastFileId = files[files.length - 1]?.id || 0;
  const screenshotTypeList = files.filter((file) => file.type === 'screenshot');
  const lastScreenshotNumber = screenshotTypeList.length || 0;
  const attachedFilesNames = attachedFiles.map((file) => file.name);
  const isLoadingUpload = isLoading.name === 'upload' && isLoading.loading;
  const isLoadingQuestion = isLoading.name === 'question' && isLoading.loading;
  const [messeageSubscription, setMessageSubscription] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setReset(true);
    getTags();
  }, [hash]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleGoToSettings = () => {
    setIsDialogOpen(false);
    navigate('/settings');
  };

  const changeTag = (value: Item[]) => {
    setItemSelected(value);
    setTagSelected(value.map((item) => item.value));
  };

  const onCloseModal = () => {
    setPdfData(new Uint8Array());
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  const closeDialogEmail = () => {
    setOpenDialogEmail(false);
  };
  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const changeMethodSearch = (method: string) => {
    setMethodSearch(method);
  };

  const getTags = async () => {
    const data = await fetchTags();
    if (typeof data !== 'string') {
      const tags: Item[] = (await data.tags_list?.length)
        ? data.tags_list.map((tag: Tag) => ({
            label: tag.tag_name,
            value: tag.tag_name,
          }))
        : [];

      setItems(tags);
    }
  };

  const onSendQuestion = async () => {
    if (subscription.subscription_status === 'expired_or_inactive') {
      setMessageSubscription('Please renew or subscribe to a plan! ');
      setIsDialogOpen(true);
      // setShowSnackbar(true);
    } else {
      if (subscription?.quotas && subscription?.quotas?.document_retrieval > 0) {
        setIsDialogOpen(false);
        if (searchValue.trim()) {
          const formData: SearchDataType = { question: searchValue };
          if (methodSearch) {
            formData.search_source = methodSearch;
          } else {
            formData.search_source = 'all';
          }
          if (fromDate && toDate) {
            formData.start_date = fromDate.format('YYYY-MM-DD');
            formData.end_date = toDate.format('YYYY-MM-DD');
            setFromDateSelected(fromDate.format('YYYY-MM-DD'));
            setToDateSelected(toDate.format('YYYY-MM-DD'));
          }
          if (tagSelected.length > 0) {
            formData.tag = tagSelected;
            setTagsSelected(tagSelected);
          }

          setIsLoading({ name: 'question', loading: true });
          try {
            const { doc_ids, files_names, list_related_docs_content } = await search(
              formData,
              SearchMethod.DocumentRetrieval
            );
            setShowMessage('');
            let results = [];
            let emails = [];
            setRelatedDocuments([]);
            setRelatedEmails([]);
            results =
              !!files_names?.length &&
              files_names.map((file: string, index: number) => ({ name: file, link: doc_ids[index] }));
            if (list_related_docs_content.email?.content?.length > 0) {
              emails = list_related_docs_content?.email.content?.map((content: string, index: number) => ({
                sender: list_related_docs_content?.email?.sender[index],
                subject: list_related_docs_content?.email?.subject[index],
                date: list_related_docs_content?.email?.email_datetime[index],
                content: list_related_docs_content?.email?.content[index],
              }));
            }

            if (results?.length || emails?.length) {
              if (results?.length) {
                setRelatedDocuments(results);
              }
              if (emails?.length) {
                setRelatedEmails(emails);
              }
            } else {
              // setShowSnackbar(true);
              setShowMessage('We could not find any data for your search. Please change your search selections.');
            }

            setSearchResultOf(searchValue);
            setSearchValue('');
            setItemSelected([]);
            setFromDate(null);
            setToDate(null);
          } catch {
            setIsLoading({ name: 'question', loading: false });
            setShowMessage('No response was found for your search.Please try again.');
          }
          setIsLoading({ name: 'question', loading: false });
          const userSubscription = await checkUserSubscription();
          if (typeof userSubscription !== 'string') {
            dispatch({ type: 'subscription', value: userSubscription });
          }
        }
      } else {
        setMessageSubscription("You've reached your limit of Document Retrieval. Upgrade for unlimited access.");
        setIsDialogOpen(true);
        // setShowSnackbar(true);
      }
    }
  };

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files?.length) {
      setAttachedFiles(Object.values(files));
      setOpenConfirmationModal({ type: 'file', open: true });
    }
  };

  const onScreenshotClick = () => dispatch({ type: 'screenshotMode', value: !screenshotMode });

  const onTakeScreenshot = (newLink: HTMLAnchorElement) => {
    {
      setLink(newLink);
      setOpenConfirmationModal({ type: 'screenshot', open: true });
    }
  };
  const onPreviewDialog = (document_id: number, filename: string) => {
    {
      setOpenConfirmationModal({ type: 'file', open: true });
      downloadDataFile(document_id, filename);
      setFilename(filename);
      setDocumentId(document_id);
    }
  };
  const showContentEmail = (email: EmailDataType) => {
    setEmailSelected(email);
    setOpenDialogEmail(true);
  };
  const downloadDataFile = (document_id: number, filename: string) => {
    setLoadingDownload(true);
    downloadRecentFile(filename, document_id).then((result) => {
      if (typeof result === 'object') {
        setLoadingDownload(false);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const uint8Array = new Uint8Array(reader.result);
            if (uint8Array.length === 0) {
              return;
            }
            setPdfData(new Uint8Array(uint8Array));
          }
        };
        reader.readAsArrayBuffer(result);
      }
      setLoadingDownload(false);
    });
  };
  const onRetry = () => {
    if (filename) {
      downloadDataFile(documentId, filename);
    }
  };

  const onConfirmAttachment = () => {
    {
      if (openConfirmationModal.type === 'screenshot') {
        link?.download &&
          setFiles([...files, { id: lastFileId + 1, name: link?.download, files: link?.href, type: 'screenshot' }]);
      } else {
        let temp: UploadedFilesType = [];
        for (let i = 0; i < attachedFiles.length; i++) {
          temp = [
            ...temp,
            { id: lastFileId + 1 + i, name: attachedFiles[i].name, files: attachedFiles[i], type: 'file' },
          ];
          setFiles([...files, ...temp]);
        }
      }
      onCloseModal();
      setLink(null);
      setAttachedFiles([]);
    }
  };

  const onDeleteAddedFile = (fileId: number) => {
    const removedFiles = files.filter((file) => file.id !== fileId);
    setFiles(removedFiles);
  };

  return {
    files,
    searchValue,
    onSearchInputChange,
    onSendQuestion,
    onInputFileChange,
    onScreenshotClick,
    screenshotMode,
    lastScreenshotNumber,
    onTakeScreenshot,
    preview: link?.href || attachedFilesNames,
    onConfirmAttachment,
    onDeleteAddedFile,
    relatedDocuments,
    relatedEmails,
    showMessage,
    isLoadingQuestion,
    isLoadingUpload,
    isModalOpen: openConfirmationModal.open,
    onCloseModal,
    pdfData,
    onPreviewDialog,
    searchResultOf,
    changeTag,
    itemSelected,
    changeMethodSearch,
    methodSearch,
    reset,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    fromDateSelected,
    toDateSelected,
    tagsSelected,
    loadingDaownload,
    onRetry,
    showContentEmail,
    emailSelected,
    openDialogEmail,
    closeDialogEmail,
    messeageSubscription,
    showSnackbar,
    setShowSnackbar,
    isDialogOpen,
    handleClose,
    handleGoToSettings,
  };
};

export default useDocumentRetrieval;
