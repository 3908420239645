import { createContext, useContext, useReducer } from 'react';
import { UserSubscriptionDataModel } from 'src/modules/General/api';

type StateType = {
  screenshotMode: boolean;
  photos: string[];
  openCamera: boolean;
  statusUpload: string;
  subscription: UserSubscriptionDataModel;
};

type ActionType =
  | { type: 'screenshotMode'; value: StateType['screenshotMode'] }
  | { type: 'photos'; value: StateType['photos'][number] }
  | { type: 'openCamera'; value: StateType['openCamera'] }
  | { type: 'statusUpload'; value: StateType['statusUpload'] }
  | { type: 'clearPhotos' }
  | { type: 'subscription'; value: StateType['subscription'] };

const initialState = {
  screenshotMode: false,
  photos: [],
  openCamera: false,
  statusUpload: '',
  subscription: {
    active: false,
    user_id: 0,
    email: '',
    first_name: '',
    last_name: '',
    full_name: '',
    exp: 0,
    subscription_status: '',
    quotas: {
      interactive: 0,
      document_retrieval: 0,
      users: 0,
      email_accounts: 0,
      total_free_pages: 0,
      doc_length: 0,
      num_primary_email_msgs: 0,
    },
  },
};

const AppContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

const mainReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'screenshotMode': {
      return { ...state, screenshotMode: action.value };
    }
    case 'openCamera': {
      return { ...state, openCamera: action.value };
    }
    case 'statusUpload': {
      return { ...state, statusUpload: action.value };
    }
    case 'photos': {
      return { ...state, photos: [...state.photos, action.value] };
    }
    case 'clearPhotos': {
      return { ...state, photos: [] };
    }
    case 'subscription': {
      return { ...state, subscription: action.value };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
};
