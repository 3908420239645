import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/core/contexts/_app.context';
import { useEmailsContext } from 'src/core/contexts/emails.context';
import {
  AddEmailsDataType,
  EmailAccount,
  RecentEmail,
  activateEmail,
  addEmails,
  changePasswordEmailAccount,
  deactivateEmail,
  processEmail,
  removeEmail,
  retriveAllEmails,
} from 'src/modules/Emails/api';
import { ChangeEmailsDataType } from 'src/modules/Emails/containers/EmailChangePassword/index.types';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';
import { checkUserSubscription } from 'src/modules/General/api';

//TYPES

interface Snackbar {
  display: boolean;
  color: string;
  message: string;
}

export interface Email {
  id: number;
  sender: string;
  subject: string;
  date: string;
  content: string;
  contentRow: string;
  attachment: string;
}

//Convertors
const convertToEmailsList = (list: Array<EmailAccount>) => {
  return list?.length
    ? list?.map((email: EmailAccount) => ({
        id: email.uid,
        email: email.address,
        host: email.host,
        count: email.processed_emails_count,
        active: email.activate,
        is_connected: email.is_connected,
      }))
    : [];
};

const useEmailsPage = () => {
  const autoUpdateRef = useRef(false);
  const unknownError = 'An unknown error occurred.';
  const { dispatch: emailsDispatch } = useEmailsContext();
  const { state, dispatch } = useAppContext();
  const { subscription } = state || {};
  const { t } = useTranslation();
  const [emailsList, setEmailsList] = useState<EmailProps[]>([]);
  const [emailName, setEmailName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [host, setHost] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [isError, setisError] = useState(false);
  const [openChangePasswordModal, setOpenPasswordModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<Snackbar>({ display: false, color: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [messeageSubscription, setMessageSubscription] = useState<string>('');

  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ name: 'remove' | 'deactivate'; open: boolean }>({
    name: 'remove',
    open: false,
  });

  const updateEmailsList = async (call: boolean, processCal: boolean) => {
    if (processCal) {
      const process = await processEmail();
      setShowSnackbar({ display: true, color: 'info', message: process.message });
    }

    if (call) {
      const data = await retriveAllEmails();
      if (typeof data !== 'string') {
        setEmailsList(convertToEmailsList(data.data));
      }
    } else {
      setTimeout(async () => {
        const addedEmails = await retriveAllEmails();
        if (typeof addedEmails !== 'string') {
          setEmailsList(convertToEmailsList(addedEmails.data));
        }
      }, 30000);
    }
  };
  const firstReload = async () => {
    setIsLoading(true);
    const _ = await processEmail();
    setTimeout(async () => {
      const data = await retriveAllEmails();
      emailsDispatch({ type: 'showContent', value: false });
      setIsLoading(false);
      if (typeof data !== 'string') {
        setEmailsList(convertToEmailsList(data.data));
        setIsLoading(false);
      }
    }, 1000);
  };
  const autoUpdate = async () => {
    if (!autoUpdateRef.current) return;
    const _ = await processEmail();
    setTimeout(async () => {
      const data = await retriveAllEmails();
      if (typeof data !== 'string') {
        setEmailsList(convertToEmailsList(data.data));
      }
    }, 300000);
  };

  const onAddEmail = async (formData: AddEmailsDataType) => {
    if (subscription.subscription_status === 'expired_or_inactive') {
      setisError(true);
      setMessageSubscription('Please renew or subscribe to a plan! Please go to settings and check your subscription.');
      setShowSnackbar({
        display: true,
        color: 'error',
        message: 'Please renew or subscribe to a plan! Please go to settings and check your subscription.',
      });
    } else {
      if (subscription?.quotas && subscription?.quotas?.email_accounts > 0) {
        setisError(false);
        setIsLoading(true);
        try {
          const response = await addEmails(formData);
          setIsLoading(false);

          if (response.error_code === 0) {
            setOpenEmailModal(false);
            setIsLoading(false);
            updateEmailsList(true, true);
            setShowSnackbar({ display: true, color: 'success', message: response?.message });
            const userSubscription = await checkUserSubscription();
            if (typeof userSubscription !== 'string') {
              dispatch({ type: 'subscription', value: userSubscription });
            }
          } else {
            setShowSnackbar({ display: true, color: 'error', message: response?.message });
          }
        } catch (error: unknown) {
          setIsLoading(false);
          setisError(false);
          if (error instanceof Error) {
            setShowSnackbar({ display: true, color: 'error', message: error.message });
          } else {
            console.error('An unknown error occurred.');
            setShowSnackbar({ display: true, color: 'error', message: unknownError });
          }
        }
      } else {
        setMessageSubscription("You've reached your limit of Email Accounts. Upgrade for unlimited access.");
        setisError(true);
        setShowSnackbar({
          display: true,
          color: 'error',
          message:
            "You've reached your limit of Email Accounts. Upgrade for unlimited access. Please go to settings and check your subscription.",
        });
      }
    }
  };
  const onRemoveEmailClick = (email: string) => {
    setEmailName(email);
    setOpenConfirmationModal({ name: 'remove', open: true });
  };
  const onRemoveEmail = async () => {
    setIsLoading(true);
    try {
      const response = await removeEmail(emailName);

      if (response?.error_code == 0) {
        updateEmailsList(true, false);
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
        emailsDispatch({ type: 'emails', value: [] });
        emailsDispatch({ type: 'showContent', value: false });
        emailsDispatch({ type: 'emailAddress', value: '' });
        emailsDispatch({ type: 'count', value: 0 });
        const userSubscription = await checkUserSubscription();
        if (typeof userSubscription !== 'string') {
          dispatch({ type: 'subscription', value: userSubscription });
        }
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    closeConfirmationModal();
  };
  const onActiveOrDeactiveEmail = async (isActive: boolean, email: string) => {
    try {
      if (isActive) {
        setEmailName(email);
        setOpenConfirmationModal({ name: 'deactivate', open: true });
      } else {
        const response = await activateEmail(email);
        if (response?.error_code === 0) {
          updateEmailsList(true, false);
          setShowSnackbar({ display: true, color: 'success', message: response?.message });
        } else {
          setShowSnackbar({ display: true, color: 'error', message: response?.message });
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
  };
  const onDeactivateEmail = async () => {
    setIsLoading(true);
    try {
      const response = await deactivateEmail(emailName);
      if (response?.error_code === 0) {
        updateEmailsList(true, false);

        setShowSnackbar({ display: true, color: 'success', message: response?.message });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    closeConfirmationModal();
  };
  const handleCloseSnackbar = () => setShowSnackbar({ display: false, message: '', color: '' });
  const closeConfirmationModal = () => {
    setIsLoading(false);
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  const handleOpenCahngePasswordModal = (address: string, host: string) => {
    setOpenPasswordModal(true);
    setEmailAddress(address);
    setHost(host);
  };
  const changePasswordEmail = async (formData: ChangeEmailsDataType) => {
    setIsLoading(true);
    try {
      const response = await changePasswordEmailAccount(formData);
      if (response?.error_code == 0) {
        setTimeout(() => {
          updateEmailsList(true, true);
        }, 2000);

        setShowSnackbar({ display: true, color: 'success', message: response?.message });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    handleCloseChangePasswordEmailModal();
  };
  const handleCloseChangePasswordEmailModal = () => {
    setOpenPasswordModal(false);
    setEmailAddress('');
  };
  useEffect(() => {
    autoUpdateRef.current = true;
    firstReload();
    const intervalId = setInterval(() => {
      autoUpdate();
    }, 500000);

    return () => {
      autoUpdateRef.current = false;
      clearInterval(intervalId);
    };
  }, []);
  return {
    emailsList,
    isLoading,
    onAddEmail,
    openEmailModal,
    setOpenEmailModal,
    handleCloseSnackbar,
    openConfirmationModal,
    closeConfirmationModal,
    onRemoveEmailClick,
    onRemoveEmail,
    showSnackbar,
    updateEmailsList,
    setOpenConfirmationModal,
    onDeactivateEmail,
    onActiveOrDeactiveEmail,
    handleOpenCahngePasswordModal,
    openChangePasswordModal,
    setOpenPasswordModal,
    changePasswordEmail,
    handleCloseChangePasswordEmailModal,
    emailAddress,
    host,
    isError,
    messeageSubscription,
    isOpenConfirmationModal: openConfirmationModal.open,
    isRemoveAction: openConfirmationModal.name === 'remove',
    messageConfirmationModal: `${t('Are you sure to')} ${openConfirmationModal.name} ${t('this Email Account')}?`,
  };
};

export default useEmailsPage;
