import { useState, useEffect } from 'react';
import { forgetPassword } from 'src/modules/General/api';
import { isStrongPassword, isValidEmail } from 'src/core/utilities/validations';
import Loading from 'src/modules/General/components/Loading';
import Right from 'src/modules/Authentication/components/Right';
import Logo from 'src/modules/General/components/Logo';
import Title from 'src/modules/Authentication/components/Title';
import Input from 'src/modules/General/components/Input';

const ForgetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccessMessage('');
    if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
   
    setIsLoading(true);
    try {
      const forgetPAsswordRespond = await forgetPassword({ email: email });
      setSuccessMessage(forgetPAsswordRespond.message);
    } catch (error) {
      console.log(error)
      setErrorMessage('An error occurred while trying to log in. Please try again.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setErrorMessage('');
  }, [email]);
  return (
    <div className="w-full h-full flex flex-row bg-white">
      <div className="w-5/12 flex items-center justify-center px-8 overflow-y-auto max-md:w-full max-sm:px-2">
        <div className="max-w-640 h-auto flex flex-col gap-y-8 max-xs:w-full">
          <Logo />
          <Title title="Forgot Password" />
          {!successMessage ? (
            <form onSubmit={handleSubmit} className="min-w-80 flex flex-col justify-start gap-y-4  max-xs:w-full">
              <Input
                title="Email"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder="Enter Your Email"
                inputStyle="rounded-lg"
              />
              {errorMessage && <p className="text-center text-red-600">{errorMessage}</p>}
              <button
                disabled={isLoading}
                type="submit"
                className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
                {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                Continue
              </button>
            </form>
          ) : (
            <div className="w-96 flex flex-col gap-y-7">
              <p className="text-center">{successMessage}</p>
              <button
                onClick={() => setSuccessMessage('')}
                className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
                Again
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="w-7/12 block max-md:hidden">
        <Right />
      </div>
    </div>
  );
};

export default ForgetPassword;
