import request from 'src/core/api/axios';

export enum SearchMethod {
  Normal = 0,
  DocumentRetrieval,
  Fainancial,
  PurchaseTax,
}

export //TYPES
type SearchDataType = {
  question: string;
  search_level?: number;
  max_num_islands?: number;
  max_num_partials_within_island?: number;
  // method: SearchMethod;
  extra?: Record<string, unknown>;
  search_source?: string;
  tag?: string[];
  start_date?: string;
  end_date?: string;
};

type ParserDataType = {
  files: Array<File>;
  local_user_datetime: string;
  concatenate?: boolean;
  note_by_user?: string;

  notif_needed?: boolean;
  files_names: Array<string>;
};

export type RecentDataType = {
  number_of_records?: number;
  tag?: string[];
  start_date?: string;
  end_date?: string;
  page_size: number;
  page?: number;
};
export interface RespondFile {
  Details: {
    document: number | string;
    partialdoc?: number;
  };
  Document: string;
  ErrorCode: number;
  error_message: string;
}
export interface Files {
  result: RespondFile[];
}
export interface DeleteDocumentsType {
  document_id: number;
  filename: string;
}

export async function search(formData: SearchDataType, searchMethod: SearchMethod): Promise<any> {
  console.log(formData);
  let url = '';
  if (searchMethod === SearchMethod.DocumentRetrieval) {
    url = '/retrieval';
  }
  if (searchMethod === SearchMethod.Normal) {
    url = '/interactive';
  }

  const { data } = await request.post<any>(url, formData);
  console.log(data);
  return data;
}

export async function analyzer({
  files = [],
  concatenate,
  note_by_user,
  notif_needed,
  local_user_datetime,
}: ParserDataType): Promise<Files> {
  const formData = new FormData();

  if (concatenate) {
    formData.append('concatenate', concatenate.toString());
  }

  if (note_by_user) {
    formData.append('note_by_user', note_by_user);
  }

  if (notif_needed) {
    formData.append('notif_needed', notif_needed.toString());
  }
  formData.append('local_user_datetime', local_user_datetime);

  files.forEach((file) => {
    formData.append(`files`, file, file.name);
  });

  const { data } = await request.post<Files>('/analyzer', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function getRecentFiles(formData: RecentDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/recent_files', formData);
    return data;
  } catch (error) {}
}

export async function getRecentSearches(formData: RecentDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/recent_searches', formData);

    return data;
  } catch (error) {}
}
export async function deleteFile(
  document_id: number | null,
  filename: string | null,
  document_link: string
): Promise<any> {
  const body: { filename: string; document_id?: number; document_link?: string } = {
    filename: filename ?? '',
  };
  if (document_id) {
    body.document_id = document_id;
  } else {
    body.document_link = document_link;
  }

  try {
    const data = await request.post<any>('/delete-document', body);
    return data;
  } catch (error) {}
}
export async function multiplDeleteFile(documents: DeleteDocumentsType[]): Promise<any> {
  try {
    const data = await request.post<any>('/delete-documents', { documents });
    return data;
  } catch (error) {}
}
export async function downloadFile(fileName: string): Promise<Blob | undefined> {
  try {
    const data = await request.get<Blob>('/download/' + fileName, { responseType: 'blob' });
    const blob = new Blob([(await data).data]);
    return blob;
  } catch (error) {}
}
export async function downloadRecentFile(filename: string, document_id: number): Promise<Blob | undefined> {
  try {
    const data = await request.post<Blob>('/download-document', { filename, document_id }, { responseType: 'blob' });
    const blob = new Blob([(await data).data]);
    return blob;
  } catch (error) {
    return undefined;
  }
}
