import { useState } from 'react';
import { useQuereisContext } from 'src/core/contexts/queries.context';
import { DeleteDocumentsType, deleteFile, getRecentFiles, multiplDeleteFile } from 'src/modules/Queries/api';

const useList = () => {
  const { dispatch, state } = useQuereisContext();

  const { documents, searches, recent_files } = state || {};

  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileLink, setFileLink] = useState<string>('');
  const [fileLinkComplete, setFileLinkComplete] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docId, setDocId] = useState<number | null>(null);
  const [selectedFileIds, setSelectedFileIds] = useState<DeleteDocumentsType[]>([]);
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const openDialog = (fileName: string, fileLink: string, documentId: number, linkComplete: string) => {
    setOpenConfirmationModal(true);
    setFileName(fileName);
    setFileLink(fileLink);
    setFileLinkComplete(linkComplete);
    setDocId(documentId);
    const file = { document_id: documentId, filename: fileName };
    setSelectedFileIds([file]);
  };
  const closeDailog = () => {
    setOpenConfirmationModal(false);
    setFileName('');
    setDocId(null);
    setIsLoading(false);
  };
  const deleteDocument = async () => {
    setIsLoading(true);
    const data = await multiplDeleteFile(selectedFileIds);

    if (data?.data?.error_code === 0) {
      updateRecentFiles();
      setIsError(false);
      setMessage('File Deleted.');
      setIsLoading(false);
    } else {
      setIsError(true);
      setMessage('Delete Faild!');
    }
    closeDailog();
    setTimeout(() => {
      setMessage('');
    }, 5000);
  };
  const updateRecentFiles = async () => {
    const files = recent_files;
    const docs = documents;

    const updatedFiles = files?.filter((file) => file.file_id !== docId);
    setDocId(null);

    const documentsSearch = docs?.filter((doc) => doc.link !== fileLinkComplete);
    dispatch({ type: 'documents', value: documentsSearch });
    setFileLinkComplete('');

    dispatch({ type: 'recent_files', value: updatedFiles });
  };

  return {
    openDialog,
    closeDailog,
    openConfirmationModal,
    deleteDocument,
    isLoading,
    message,
    setMessage,
    isError,
  };
};
export default useList;
