import Header from 'src/modules/General/containers/Layout/Header';
import RightColumn from './RightColumn';
import LeftColumn from './LeftColumn';
import { NotificationsProvider, useNotificationsContext } from 'src/core/contexts/notifications.context';
import HeaderMenu from './HeaderMenu';
import useNotificationPage from './useNotificationPage.services';
import { useEffect } from 'react';

const Notifications = () => {
  const {
    notificationsList,
    notifications,
    onGetNotificationsByDateRang,
    getStatusNotifications,
    fromDate,
    setFromDate,
    setToDate,
    toDate,
    isLoadingData,
    selectedNotifications,
    setSelectedNotifications,
    selectAll,
    setSelectAll,
    removeNotification,
    displaySnackbar,
    loadingDelete,
    openDeleteNotificationModal,
    openSingleDeleteNotificationModal,
    openDialogDeleteNotification,
    openDialogMultiDeleteNotification,
    closeDeleteNotificationDialog,
    handleCloseSnackbar,
    closeMultiDeleteNotificationDialog
  } = useNotificationPage();
 
  return (
    <>
      <Header />
      <div className="w-sideBar flex  h-full max-lg:h-calc-120 max-lg:w-full max-lg:block max-lg:gap-0 max-sm:p-2">
        <div className="hidden w-full h-15 mb-2 max-lg:block">
          <HeaderMenu
            notificationsList={notificationsList}
            onGetNotificationsByDateRang={onGetNotificationsByDateRang}
            getStatusNotifications={getStatusNotifications}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </div>
        <div className="w-calc-384 px-6 py-8 bg-gray-200  max-lg:w-full max-lg:h-calc-68 max-sm:p-1">
          <LeftColumn 
          openSingleDeleteNotificationModal={openSingleDeleteNotificationModal} 
          closeMultiDeleteNotificationDialog={closeMultiDeleteNotificationDialog} 
          openDeleteNotificationModal={openDeleteNotificationModal} 
          closeDeleteNotificationDialog={closeDeleteNotificationDialog} 
          openDialogDeleteNotification={openDialogDeleteNotification} 
          openDialogMultiDeleteNotification={openDialogMultiDeleteNotification} 
          loadingDelete={loadingDelete} displaySnackbar={displaySnackbar} 
          handleCloseSnackbar={handleCloseSnackbar} 
          removeNotification={removeNotification} 
          notifications={notifications} 
          isLoading={isLoadingData} 
          selectAll={selectAll} 
          setSelectAll={setSelectAll} 
          selectedNotifications={selectedNotifications} 
          setSelectedNotifications={setSelectedNotifications}/>
        </div>

        <div className="w-96 max-lg:hidden">
          <RightColumn
            notificationsList={notifications}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            onGetNotificationsByDateRang={onGetNotificationsByDateRang}
            getStatusNotifications={getStatusNotifications}
          />
        </div>
      </div>
    </>
  );
};

export default Notifications;
