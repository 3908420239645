import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InviteData, InviteLink, introspect, login } from 'src/modules/General/api';
import { Link } from '@mui/material';
import { isValidEmail } from 'src/core/utilities/validations';
import useAuth from 'src/core/hooks/useAuth';
import Loading from 'src/modules/General/components/Loading';
import Right from 'src/modules/Authentication/components/Right';
import Logo from 'src/modules/General/components/Logo';
import Title from 'src/modules/Authentication/components/Title';
import Input from 'src/modules/General/components/Input';
import InviteLinkModal from 'src/modules/Authentication/components/InviteLinkModal';

const Login: React.FC = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const from = '/';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingInvite, setIsLoadingInvite] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/documents');
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else if (!password) {
      setErrorMessage('Password field cannot be empty.');
    } else {
      setIsLoading(true);
      try {
        const loginRespond = await login({ email: email, password: password });

        const introspectRespond = await introspect();
        const userToken = {
          accessToken: loginRespond.access_token,
          refreshToken: loginRespond.refresh_token,
          tokenType: loginRespond.token_type,
        };

        const userData = {
          isActive: introspectRespond.active,
          userID: introspectRespond.user_id,
          email: introspectRespond.email,
          firstName: introspectRespond.first_name,
          lastName: introspectRespond.last_name,
          username: introspectRespond.username,
          userRole: introspectRespond.user_role,
          expirationDate: introspectRespond.exp,
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('access_token', loginRespond.access_token);
        localStorage.setItem('refresh_token', loginRespond.refresh_token);

        setAuth({ userToken, userData });

        if (userData.isActive) {
          setEmail('');
          setPassword('');
          setErrorMessage('');
          navigate(from, { replace: true });
          localStorage.setItem('language', 'en');
        } else {
          setErrorMessage('It seems your account is currently inactive.');
        }
      } catch (e: any) {
        console.log(e);
      }
      setIsLoading(false);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleSubmit(e);
  };
  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const onSubmitEmail = async (body: InviteData) => {
    setIsLoadingInvite(true);

    const InviteRespond = await InviteLink(body);
    setIsLoadingInvite(false);
    setOpenRegisterModal(false);
  };
  const downloadLink = () => {
    window.open('https://nexadeeds.com/download/', '_blank');
  };
  return (
    <div className="w-full h-full flex flex-row bg-white">
      <div className="w-5/12 flex items-center justify-center px-8 overflow-y-auto max-md:w-full max-sm:px-2">
        <div className="max-w-640 h-auto flex flex-col gap-y-8 max-xs:w-full">
          <Logo />
          <Title title="Welcome back to DeedDex" />
          <form onSubmit={handleSubmit} className="min-w-80 flex flex-col justify-start gap-y-4  max-xs:w-full">
            <Input
              title="Email"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              handleKeyDown={handleKeyDown}
              placeholder="Enter Your Email"
              inputStyle="rounded-lg"
            />
            <Input
              title="Password"
              eyeIcon={true}
              type={showPassword ? 'text' : 'password'}
              id="password"
              containerStyle="relative"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              handleKeyDown={handleKeyDown}
              handleTogglePassword={handleTogglePassword}
              placeholder="Enter Your Password"
              inputStyle="rounded-lg"
            />
            <div className="w-full flex items-center justify-between flex-row">
              <div className="flex gap-x-1">
                <input type="checkbox" />
                <span className="text-neutral-950 text-base font-medium">Remember me</span>
              </div>
              <Link
                href="/user-forget-password"
                className="text-primary text-base font-medium capitalize leading-tight no-underline">
                Forgot your password?
              </Link>
            </div>
            {errorMessage && <p className="text-red-600 text-base text-center">{errorMessage}</p>}
            <button
              disabled={isLoading}
              type="submit"
              className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
              {isLoading && <Loading variant="circle" size={20} color="inherit" />}
              Login
            </button>
          </form>
          <div className="w-full flex items-center justify-center">
            <button onClick={downloadLink} className="text-primary border-none bg-transparent">
              Download Andriod Version
            </button>
          </div>
          <div className="w-full flex items-center justify-center flex-row gap-x-1">
            <p>Don’t have an account?</p>
            <p
              className="text-primary text-base font-medium capitalize leading-tight cursor-pointer"
              onClick={() => {
                navigate('/user-sign-up');
              }}>
              Register
            </p>
          </div>
        </div>
      </div>
      <div className="w-7/12 block max-md:hidden">
        <Right />
      </div>
      <InviteLinkModal
        open={openRegisterModal}
        onClose={() => setOpenRegisterModal(false)}
        onSubmitEmail={onSubmitEmail}
        isLoading={isLoadingInvite}
      />
    </div>
  );
};

export default Login;
