import { useNavigate, useLocation } from 'react-router-dom';
import { DocumentsProvider } from 'src/core/contexts/documents.context';
import Tabs from 'src/modules/Queries/containers/Tabs';
import Uplaod from '../Upload';
import MyDoucuments from '../MyDocuments';
import Header from 'src/modules/General/containers/Layout/Header';

const DocumentsSection = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const TabItems = ['Add Document', 'My Documents'];

  const RenderTabs: Record<string, React.ReactNode> = {
    '#add_document': <Uplaod />,
    '#my_documents': <MyDoucuments />,
  };

  const handleTabSelect = (tab: string) => {
    console.log();
    navigate(`#${tab}`);
  };

  return (
    <DocumentsProvider>
      <Header />
      <div className="w-sideBar flex h-full max-lg:h-calc-120 bg-Gray-ED overflow-hidden  py-8 px-6 max-lg:w-full max-sm:p-2">
        <div className="w-full h-full flex justify-start flex-col gap-y-4">
          <Tabs tabs={TabItems} onSelectTab={handleTabSelect} selectedTab={hash} />
          <div className="w-full flex content-normal justify-start flex-col gap-8 bg-transparent h-calc-48 overflow-y-auto">
            {hash ? RenderTabs[hash] : <Uplaod />}
          </div>
        </div>
      </div>
    </DocumentsProvider>
  );
};

export default DocumentsSection;
