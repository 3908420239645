import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SalesTypes } from '../../api';
import { SalesListProps } from './index.types';
import { useTranslation } from 'react-i18next';

const SalesTax = ({ SalesTax }: SalesListProps) => {
  const { t } = useTranslation();
  return (
    <Table size="small" className="w-full overflow-x-auto">
      <TableHead>
        <TableRow className="h-11 px-6 py-3 bg-white border-b border-gray-200 text-neutral-950 text-xs font-bold font-['Inter'] leading-snug">
          <TableCell>{t('Seller Name')}</TableCell>
          <TableCell align="left">{t('Transaction numbers')}</TableCell>
          <TableCell align="left">QST</TableCell>
          <TableCell align="left">GST</TableCell>
          <TableCell align="left">{t('Total sales tax')}</TableCell>
          <TableCell align="left">{t('Total purchase')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {SalesTax?.map((data: SalesTypes, index: number) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className="h-72 px-6 py-4 bg-white border-b border-gray-200 text-neutral-950 text-sm font-normal font-['Inter'] leading-[16.80px]">
            <TableCell component="th" scope="row">
              {data['Seller Name']}
            </TableCell>
            <TableCell align="left">
              {data['Transaction numbers']?.map((transaction: string, index: number) => (
                <span key={transaction}>
                  {transaction} {data['Transaction numbers']?.length - 1 === index ? '' : ','}
                </span>
              ))}
            </TableCell>
            <TableCell align="left">{data?.QST}</TableCell>
            <TableCell align="left">{data?.GST}</TableCell>
            <TableCell align="left">{data['Total sales tax']}</TableCell>
            <TableCell align="left">{data['Total purchase']}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SalesTax;
