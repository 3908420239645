import { formatDate } from 'src/core/utilities/general';
import { NotificationProps } from './index.types';
import NotificationDialog from '../../containers/NotificationDialog';
import notificationService from './index.services';
import CheckIcon from 'src/assets/images/Queries/check.svg';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import Loading from 'src/modules/General/components/Loading';
import { Dispatch, SetStateAction } from 'react';

interface NotificationComponentProps extends NotificationProps {
  selectedNotifications: number[];
  handleSelectNotification: (id: number) => void;
  // setSelectedNotifications: Dispatch<SetStateAction<number[]>>,
  openDialogDeleteNotification: (id: number) => void,
  closeDeleteNotificationDialog: () => void,
  openDeleteNotificationModal: boolean,
  keyIndex: number
}

const Notification = ({ keyIndex,notification, notifications, selectedNotifications,handleSelectNotification,openDialogDeleteNotification,closeDeleteNotificationDialog ,openDeleteNotificationModal}: NotificationComponentProps) => {
  const { openDialog, openDialogNotification, setOpen, loading,removeSingleNotification } = notificationService();
  const { t } = useTranslation();

  return (
    <div key={keyIndex} className="w-full h-auto py-6 px-4 bg-slate-50 rounded-lg relative shadow-lg">
      <label className="block relative pl-8 mb-3 -top-4 cursor-pointer text-2xl select-none">
        <input
          type="checkbox"
          checked={selectedNotifications.includes(notification.NotificationID)}
          onChange={() => handleSelectNotification(notification.NotificationID)} 
          className="absolute opacity-0 cursor-pointer h-0 w-0"
        />
        <span
          className={`absolute top-0 left-0 h-6 w-6 rounded transition-all duration-200
            ${selectedNotifications.includes(notification.NotificationID) ? 'bg-orange-400 border-orange-400' : 'bg-white border-orange-400'} border-2`}
        >
          <span
            className={`absolute left-1.5 top-0.5 w-1.5 h-3.5 border-white border-r-2 border-b-2 transform
              ${selectedNotifications.includes(notification.NotificationID) ? 'block rotate-45' : 'hidden'}`}
          />
        </span>
      </label>

      <div className="absolute top-2 right-2">
        <span className={`relative h-3 w-3 ${!notification?.Seen ? 'flex' : 'hidden'}`}>
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
        </span>
      </div>

      <div className="flex items-center justify-between flex-row mb-2">
        <h3 className="text-neutral-700 text-lg font-normal leading-snug truncate flex flex-row items-center gap-2 max-sm:text-sm">
          {notification?.Title}
          {notification?.Resolved && (
            <div className="bg-emerald-600 border-emerald-600 w-5 h-5 p-1 rounded-[10px] border justify-center items-center inline-flex">
              <img src={CheckIcon} alt="check" />
            </div>
          )}
        </h3>
        <p className="text-zinc-400 text-xs font-normal">
          {t('Due Date')} : {formatDate(notification?.DateTime ?? '')}
        </p>
      </div>

      <p className="text-neutral-700 text-sm font-normal mb-2 truncate max-sm:text-xs">
        {notification?.Description}
      </p>

      <div className="flex gap-2 justify-end">
      <button
          onClick={()=> openDialogDeleteNotification(notification.NotificationID)}
          className="h-11 px-4 py-3 bg-white rounded-xl shadow border border-red-600 justify-center items-center gap-2 inline-flex text-red-600 text-base font-medium max-sm:text-sm"
        >
          {t('Delete')}
        </button>
        <button
          onClick={() => openDialogNotification(notification?.DateTime ?? '', notification?.Seen ?? false)}
          className="h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium max-sm:text-sm"
        >
          {t('Show')}
        </button>
       
      </div>
      <ConfirmationModal
          open={openDeleteNotificationModal}
          onClose={closeDeleteNotificationDialog}
          text={`Are you sure to delete ${selectedNotifications.length > 1 ? 'notifications?' : 'this notification?'}`}
          buttons={[
            {
              children: t('Remove'),
              variant: 'contained',
              color: 'error',
              onClick:   removeSingleNotification,
              endIcon: loading && <Loading variant="circle" size={20} color="inherit" />,
            },
            {
              children: t('Cancel'),
              variant: 'contained',
              color: 'success',
              onClick: closeDeleteNotificationDialog,
            },
          ]}
        />
      <NotificationDialog
        open={openDialog}
        onClose={() => setOpen(false)}
        isLoading={false}
        notifications={notifications ?? []}
        notification={notification}
      />
    </div>
  );
};

export default Notification;
