import { useDropzone } from 'react-dropzone';
import { Divider } from '@mui/material';
import Screenshot from 'src/modules/General/containers/Screenshot';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import PreviewElement from 'src/modules/Queries/containers/PreviewElement';
import AddedFiles from 'src/modules/Queries/containers/AddedFiles';
import useAddDcoument, { MessageRespond } from './index.services';
import UploadIcon from 'src/assets/images/Queries/upload.svg';
import Camera from 'src/modules/Queries/containers/Camera';
import { useTranslation } from 'react-i18next';
import FileUploaded from 'src/modules/Documents/components/FileUploaded';
const Uplaod = () => {
  const {
    files,
    onDrop,
    onTakeScreenshot,
    isModalOpen,
    onCloseModal,
    preview,
    onConfirmAttachment,
    notifNeeded,
    onDeleteAddedFile,
    onChangeNote,
    onNotifNeeded,
    onConcatFiles,
    onUploadFiles,
    isLoading,
    errorMessages,
    photos,
    clearPhotos,
    addPhotos,
  } = useAddDcoument();
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full flex gap-4 justify-normal items-start flex-col">
        <div className="w-full flex gap-4 justify-between items-center flex-row">
          <div
            className="w-9/12 h-200 px-6 py-4 bg-white rounded-xl border border-dashed border-Gray-CB flex-col justify-center items-center gap-1 inline-flex cursor-pointer peer-focus-visible:outline-none max-sm:text-xs max-sm:p-3 max-sm:w-7/12"
            {...getRootProps()}>
            <input {...getInputProps()} />
            {/* <Icon name="upload" /> */}
            <div className="w-10 h-10 p-2 bg-Orange-25 rounded-full justify-start items-center gap-2.5 inline-flex">
              <img src={UploadIcon} alt="upload" />
            </div>
            <p className="text-Orange-500 text-base font-medium leading-tight max-sm:text-xs">
              {t('Click To Upload')}{' '}
              <span className="text-Gray-40 text-sm font-normal max-sm:text-xs">{t('or drag and drop')}</span>
            </p>
          </div>
          {/* <div
            className="h-200 w-3/12 px-6 py-4 bg-white rounded-xl border border-dashed border-Gray-CB flex-col justify-center items-center gap-1 inline-flex cursor-pointer max-sm:text-xs max-sm:p-4 max-sm:w-5/12"
            onClick={onScreenshotClick}>

            <div className="w-10 h-10 p-2 bg-Orange-25 rounded-full justify-start items-center gap-2.5 inline-flex">
              <img src={CropIcon} alt="Crop" />
            </div>
            <p className="text-Gray-40 text-sm font-normal max-sm:text-xs">Screenshot</p>
          </div> */}
          <Camera />
        </div>
        <div className="w-full flex items-center justify-normal gap-y-2 flex-col">
          <div className="w-full flex gap-x-1 flex-row overflow-x-auto">
            {photos?.length > 0 &&
              photos?.map((photo: string, index: number) => (
                <img className="rounded-lg shadow-md w-24 h-24" key={index} src={photo} alt="Captured" />
              ))}
          </div>
          {photos.length > 0 && (
            <div className="w-full flex flex-row items-center justify-center gap-2 ">
              <button
                onClick={addPhotos}
                className="px-2 h-11 bg-primary text-white flex items-center justify-center rounded-lg">
                {t('Confirm')}
              </button>
              <button
                onClick={clearPhotos}
                className="px-2 h-11 bg-red-600 text-white flex items-center justify-center rounded-lg">
                {t('Clear')}
              </button>
            </div>
          )}
        </div>

        <Divider sx={{ width: '100%', borderColor: '#ffffff' }} />
        {files && files.length > 0 && (
          <div className="w-full">
            <AddedFiles
              files={files}
              onDeleteAddedFile={onDeleteAddedFile}
              onChangeNote={onChangeNote}
              onNotifNeeded={onNotifNeeded}
              onConcatFiles={onConcatFiles}
              onSubmitFiles={onUploadFiles}
              checked={notifNeeded}
              loading={isLoading}
              className="w-full flex-none justify-between"
              actionsClassName="static self-end"
            />
          </div>
        )}
        {errorMessages.length > 0 &&
          errorMessages.map((message: MessageRespond) => (
            <FileUploaded
              key={message.id}
              docName={message.docName}
              errorCode={message.errorCode}
              id={message.id}
              message={message.message}
            />
          ))}
      </div>
      <Screenshot lastScreenshotNumber={1} onTakeScreenshot={onTakeScreenshot} />
      <ConfirmationModal
        open={isModalOpen}
        onClose={onCloseModal}
        previewElement={<PreviewElement preview={preview} />}
        text={t('Do you confirm to attach this file?')}
        buttons={[{ children: t('Confirm'), color: 'primary', variant: 'contained', onClick: onConfirmAttachment }]}
      />
    </>
  );
};

export default Uplaod;
