import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import Icon from 'src/modules/General/components/Icon';
import IconButton from '@mui/material/IconButton';
import useNotificationsRightColumn from './index.services';
import { NotificationData, RecentNotificationBody } from 'src/modules/Notifications/api';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';


interface RightNotificationProps {
  notificationsList: NotificationData[] | null;
  fromDate: Dayjs | null;
  setFromDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  toDate: Dayjs | null;
  setToDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  getStatusNotifications: (notifications: NotificationData[]) => void;
  onGetNotificationsByDateRang: (data: RecentNotificationBody) => Promise<void>;
}
const RightColumn = ({
  notificationsList,
  getStatusNotifications,
  onGetNotificationsByDateRang,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: RightNotificationProps) => {
  const { t } = useTranslation();
  const onGetNotifications = () => {
    if (fromDate && toDate) {
      const body: RecentNotificationBody = {
        start_date: fromDate?.format('YYYY-MM-DD') ?? '',
        end_date: toDate?.format('YYYY-MM-DD') ?? '',
        number_of_notifications: 20000,
      };
      onGetNotificationsByDateRang(body);
    }
  };
  useEffect(() => {
    getStatusNotifications(notificationsList ?? []);
  }, [notificationsList]);

  return (
    <div className="w-full h-full py-8 px-4 bg-white">
      <div className="w-full flex items-center justify-start flex-col gap-4">
        <div className="flex items-center justify-start flex-row gap-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('From')}
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('To')}
              minDate={fromDate ?? undefined}
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
          <IconButton aria-label="search" onClick={onGetNotifications}>
            <Icon name="search" />
          </IconButton>
        </div>
        <div className="w-full flex items-center justify-between">
          <p>{t('All Notifications')}</p>
         
          <Tooltip
                      title={`${t('Number of Notifications')} (${notificationsList?.length ?? 0})`}
                      className="w-8 h-7 p-2 bg-orange-200 rounded-full flex-col justify-center items-center inline-flex">
                     <span className="w-8 h-8 p-1 bg-orange-200 rounded-[17px] flex-col justify-center items-center gap-1 inline-flex text-neutral-950 text-xs font-normal font-['Inter'] leading-[15px]">
            {notificationsList?.length && notificationsList.length > 999 ? '999+' : notificationsList?.length ?? 0}
          </span>
                    </Tooltip>
        </div>
        {/* <div className="w-full flex items-center justify-between">
          <p>Unread Notifications</p>
          <span className="w-6 h-6 p-1 bg-orange-200 rounded-[17px] flex-col justify-center items-center gap-1 inline-flex text-neutral-950 text-xs font-normal font-['Inter'] leading-[15px]">
            {unRead}
          </span>
        </div>
        <div className="w-full flex items-center justify-between">
          <p>Read Notifications</p>
          <span className="w-6 h-6 p-1 bg-orange-200 rounded-[17px] flex-col justify-center items-center gap-1 inline-flex text-neutral-950 text-xs font-normal font-['Inter'] leading-[15px]">
            {read}
          </span>
        </div> */}
      </div>
    </div>
  );
};
export default RightColumn;
