// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   .use(Backend)
//   // .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: localStorage.getItem('language') ?? 'en',
//     debug: true,
//     interpolation: {
//       escapeValue: false,
//     },
//     react: {
//       useSuspense: false,
//     },
//   });

// export default i18n;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'src/assets/locales/en/translation.json';
import translationFA from 'src/assets/locales/fr/translation.json';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fa: {
        translation: translationFA,
      },
    },
    lng: 'en', 
    fallbackLng: 'en',
   
debug: true,
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
