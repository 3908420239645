import Modal from 'src/modules/General/components/Modal';
import { NotificationDialogProps } from './index.types';
import { formatDate } from 'src/core/utilities/general';
import CheckIcon from 'src/assets/images/Queries/check.svg';
import PreviewModal from 'src/modules/Queries/containers/PreviewModal';
import notificationDialogService from './index.services';
import { useTranslation } from 'react-i18next';
import NotificationEmailDialog from '../NotificationEmailDialog';
import Loading from 'src/modules/General/components/Loading';

const NotificationDialog = ({ open, onClose, notification, notifications }: NotificationDialogProps) => {
  const { openPreviewModal, closeDialog, resolveNotification, onPreviewDialog, pdfData,onRetry,loadingDownload,openEmailModal,loadingEmail,closeEmailDialog, openEmailDialog, errorMessage,setErrorMessage, emailInfo} = notificationDialogService();
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className=" bg-white flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full flex flex-col items-center justify-start gap-y-2">
          <span className="w-full text-neutral-950 text-sm font-semibold">{t('Details Notification')}</span>
        </div>
        <div className="w-full flex flex-col items-start justify-normal gap-2 border-dashed border-b-2 pb-2">
          <div className="w-full flex flex-row items-center justify-between gap-6">
            <div className="flex flex-row gap-1 items-center justify-normal max-md:text-xs">
              <p className="text-left font-bold">{notification?.Title}</p>
              {notification?.Resolved && (
                <div className="bg-emerald-600 border-emerald-600 w-5 h-5 p-1 rounded-[10px] border  justify-center items-center inline-flex">
                  <img src={CheckIcon} alt="check" />
                </div>
              )}
            </div>
            <span className="text-sm max-md:text-xs">{t('Due Date')}: {formatDate(notification?.DateTime ?? '')}</span>
          </div>
          <p className="text-left font-medium text-sm">{notification?.Description}</p>
        </div>

        {notification?.SourceDocID && (
          <div className="w-full flex flex-row justify-normal items-center gap-2">
          <p className="text-sm font-bold">{t('Related File')}:</p>
          <p
            className="text-xs text-primary cursor-pointer"
            onClick={() => onPreviewDialog(notification?.DocumentName ?? '', notification?.SourceDocID ?? 0)}>
            {notification?.DocumentName}
          </p>
        </div>
        )}
        
        {notification?.EmailID  && (
          <>
           <div className="w-full flex flex-row justify-normal items-center gap-2">
            <p className="text-sm font-bold">{t('Related Email')}:</p>
            <p className="cursor-pointer text-xs flex flex-row items-center gap-2 text-primary" onClick={() => openEmailDialog(notification?.EmailID ?? 0)}>Go to Email {loadingEmail && <Loading variant='circle' size={28} color='inherit' />}</p>
          </div>
          <p className='text-red-500'>{errorMessage}</p>
          </>
         
        )}
        <div className="w-full flex flex-row gap-2 items-center justify-end">
          {!notification?.Resolved && (
            <button
              onClick={() => resolveNotification(notification?.DateTime ?? '', notifications)}
              className="w-28 h-11 px-4 py-3 bg-white rounded-xl shadow border border-primary text-primary justify-center items-center gap-2 inline-flex ">
              {t('Resolve')}
            </button>
          )}

          <button
            onClick={() =>{onClose(); setErrorMessage('')} }
            className="w-28 h-11 px-4 py-3 bg-white rounded-xl shadow border border-red-600 justify-center items-center gap-2 inline-flex text-red-600">
            {t('Close')}
          </button>
        </div>
        <PreviewModal
        loading={loadingDownload}
        onRetry={onRetry}
          open={openPreviewModal}
          onClose={closeDialog}
          pdfData={pdfData}
          text="Preview File"
          buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: closeDialog }]}
        />
        <NotificationEmailDialog
          open={openEmailModal}
          onClose={closeEmailDialog}
          email={emailInfo}
        />
      </div>
    </Modal>
  );
};

export default NotificationDialog;
