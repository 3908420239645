import CheckGreenIcon from 'src/assets/images/Settings/check.png';
import CloseIcon from 'src/assets/images/Settings/close.png';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'src/modules/Settings/api';

export interface SubscriptionTable {
  name: string;
  features?: string;
  notification: boolean;
  // multiLevelAccess: boolean;
  // clientCloud: boolean;
  twoFactorAuth: boolean;
  interactive: string;
  documentRetrieval: string;
  users: number;
  emailAccounts: number;
  emailMessages: number | string;
  uploadSize: number;
  freePages: number | string;
  extraPages?: string;
  // productId: string;
  // type:string;
  // subscriptionId: number
}
const TablePlans = (props: { subscriptions: Subscription[] }) => {
  const [dataPlan, setDataPlan] = useState<SubscriptionTable[]>([]);
  const generateDataTable = () => {
    const list = props.subscriptions;
    const newData: SubscriptionTable[] = [];
    for (let i = 0; i < list.length; i++) {
      const name = list[i].Name;
      const policies = list[i].policies;
      let notification = false;
      let twoAuth = false;
      let interactive = '';
      let retrieval = '0';
      let users = 0;
      let emailAccounts = 0;
      let docLength = 0;
      let totalPageFree = 0;
      let numMsgEmail = 0;
      for (let j = 0; j < policies.length; j++) {
        if (policies[j].resource === 'notification') {
          notification = true;
        }

        if (policies[j].resource === 'two_factor_auth') {
          twoAuth = true;
        }

        if (policies[j].resource === 'users') users = policies[j]?.attribute?.count ?? 0;
        if (policies[j].resource === 'total_free_pages') totalPageFree = policies[j]?.attribute?.count ?? 0;
        if (policies[j].resource === 'doc_length') docLength = policies[j]?.attribute?.count ?? 0;
        if (policies[j].resource === 'email_accounts') emailAccounts = policies[j]?.attribute?.count ?? 0;
        if (policies[j].resource === 'document_retrieval') retrieval = `${policies[j]?.attribute?.count}` ?? '0';
        if (policies[j].resource === 'interactive') interactive = `${policies[j]?.attribute?.count}` ?? '0';
        if (policies[j].resource === 'num_primary_email_msgs') numMsgEmail = policies[j]?.attribute?.count ?? 0;
      }
      newData.push({
        name: name,
        notification: notification,
        twoFactorAuth: twoAuth,
        interactive: interactive,
        documentRetrieval: retrieval,
        users: users,
        emailAccounts: emailAccounts,
        emailMessages: numMsgEmail,
        uploadSize: docLength,
        freePages: totalPageFree,
      });
    }
    console.log(newData);
    setDataPlan(newData);
  };
  const renderIcon = (isAvailable: boolean) => (
    <img className="w-6 m-auto" src={isAvailable ? CheckGreenIcon : CloseIcon} alt={isAvailable ? 'check' : 'close'} />
  );
  useEffect(() => {
    generateDataTable();
  }, []);

  return (
    <div className="overflow-x-auto w-full plan-table">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100 text-sm border">
            <th className="border px-4 py-2">Plan Name</th>
            <th className="border px-4 py-2">Notification (Monthly)</th>
            <th className="border px-4 py-2">Two-Factor Authentication (Monthly)</th>
            <th className="border px-4 py-2">Interactive (Monthly)</th>
            <th className="border px-4 py-2">Document Retrieval (Monthly)</th>
            <th className="border px-4 py-2">Users</th>
            <th className="border px-4 py-2">Email Accounts</th>
            <th className="border px-4 py-2">Primary Email Messages (Monthly)</th>
            <th className="border px-4 py-2">Upload Size</th>
            <th className="border px-4 py-2">Free Pages (Monthly)</th>
          </tr>
        </thead>
        <tbody>
          {/* {props.subscriptions.map((subscription, index) => (
            <tr key={index} className="text-sm border text-center">
              <td className="px-4 py-2">{subscription.Name}</td>
              {subscription.policies?.map((policy, index) => (
                <>
                  <td className="px-4 py-2">{renderIcon(policy.resource === 'notification')}</td>
                  <td className="px-4 py-2">{renderIcon(policy.resource === 'two_factor_auth')}</td>
                  <td className="px-4 py-2">{policy.resource}</td>
                  <td className="px-4 py-2">{subscription.documentRetrieval}</td>
                  <td className="px-4 py-2">{subscription.users}</td>
                  <td className="px-4 py-2">{subscription.emailAccounts}</td>
                  <td className="px-4 py-2">{subscription.emailMessages}</td>
                  <td className="px-4 py-2">{subscription.uploadSize}</td>
                  <td className="px-4 py-2">{subscription.freePages}</td>
                </>
              ))}
            </tr>
          ))} */}

          {dataPlan.map((plan, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{plan.name}</td>
              <td className="px-4 py-2">{renderIcon(plan.notification)}</td>
              <td className="px-4 py-2">{renderIcon(plan.twoFactorAuth)}</td>
              <td className="px-4 py-2">{plan.interactive}</td>
              <td className="px-4 py-2">{plan.documentRetrieval}</td>
              <td className="px-4 py-2">{plan.users}</td>
              <td className="px-4 py-2">{plan.emailAccounts}</td>
              <td className="px-4 py-2">{plan.emailMessages}</td>
              <td className="px-4 py-2">{plan.uploadSize}</td>
              <td className="px-4 py-2">{plan.freePages}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePlans;

// const plans = [
//   {
//     name: 'Free Trial',
//     features: '__',
//     notification: false,
//     multiLevelAccess: false,
//     clientCloud: false,
//     twoFactorAuth: false,
//     interactive: 'Up to 10',
//     documentRetrieval: '50',
//     users: 1,
//     emailAccounts: 1,
//     emailMessages: 10,
//     uploadSize: 10,
//     freePages: 10,
//     extraPages: '__',
//   },
//   {
//     name: 'Basic',
//     features: '__',
//     notification: false,
//     multiLevelAccess: false,
//     clientCloud: false,
//     twoFactorAuth: false,
//     interactive: 'Up to 40',
//     documentRetrieval: '100',
//     users: 1,
//     emailAccounts: 1,
//     emailMessages: 100,
//     uploadSize: 10,
//     freePages: 100,
//     extraPages: '$3 per 500 extra pages',
//   },
//   {
//     name: 'Standard',
//     features: '__',
//     notification: true,
//     multiLevelAccess: false,
//     clientCloud: false,
//     twoFactorAuth: false,
//     interactive: 'Up to 150',
//     documentRetrieval: '1,000',
//     users: 1,
//     emailAccounts: 3,
//     emailMessages: 300,
//     uploadSize: 30,
//     freePages: '1,000',
//     extraPages: '$3 per 500 extra pages',
//   },
//   {
//     name: 'Premium',
//     features: '__',
//     notification: true,
//     multiLevelAccess: false,
//     clientCloud: false,
//     twoFactorAuth: false,
//     interactive: 'Up to 300',
//     documentRetrieval: '2,000',
//     users: 1,
//     emailAccounts: 4,
//     emailMessages: '1,000',
//     uploadSize: 50,
//     freePages: '2,000',
//     extraPages: '$3 per 500 extra pages',
//   },
//   {
//     name: 'Professional',
//     features: '__',
//     notification: true,
//     multiLevelAccess: true,
//     clientCloud: false,
//     twoFactorAuth: true,
//     interactive: 'Up to 600',
//     documentRetrieval: 'Unlimited',
//     users: 1,
//     emailAccounts: 10,
//     emailMessages: 'Unlimited',
//     uploadSize: 200,
//     freePages: '5,000',
//     extraPages: '$3 per 500 extra pages',
//   },
// ];
